import * as React from "react";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputBase from "@mui/material/InputBase";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
  "& .Mui-error": {
    color: "#000",
  },
}));

const StyledTextField = ({
  name,
  label,
  type = "text",
  register,
  errors,
  ...props
}) => {
  const validationRules = {
    required: "This field is required",
    ...(type === "email" && {
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Invalid email address",
      },
    }),
    ...(type === "tel" && {
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Phone number must be exactly 10 digits",
      },
    }),
  };

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel
        shrink
        htmlFor={name}
        sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
      >
        {label}
      </InputLabel>
      <TextField
        autoFocus
        sx={
          errors[name] && {
            color: "red",
            borderRadius: "6px",
          }
        }
        margin="dense"
        type={type}
        fullWidth
        size="small"
        placeholder={label}
        variant="outlined"
        {...register(name, validationRules)}
        {...props}
      />
      {errors[name] && (
        <FormHelperText sx={{ color: "red" }}>
          {errors[name]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};
const StyledTextFeildWithoutValidation = ({
  name,
  label,
  type = "text",
  register,
  errors,
  ...props
}) => {
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel
        shrink
        htmlFor={name}
        sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
      >
        {label}
      </InputLabel>
      <TextField
        sx={
          errors[name] && {
            color: "red",
            borderRadius: "6px",
          }
        }
        margin="dense"
        type={type}
        fullWidth
        size="small"
        placeholder={label}
        variant="outlined"
        {...register(name)}
        {...props}
      />
      {errors[name] && (
        <FormHelperText sx={{ color: "red" }}>
          {errors[name]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  // border: "2px solid red",
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(1),
  },

  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const SingleProfileCreaetionById = ({ reload, details }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({ mode: "onChange" });

  const [open, setOpen] = React.useState(false);
  const [cardTypes, setCardTypes] = React.useState([]);
  const [filteredCardTypes, setFilteredCardTypes] = React.useState([]);
  const navigate = useNavigate();
  const { id } = useParams();


  // Card Data
  React.useEffect(() => {
    const fetchCardTypes = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
          navigate("/sign-in");
          return;
        }

        console.log(
          "Fetching Data From SingleProfile",
          details?.OrderCustomizations
        );

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/getCardTypes`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.status === 200) {
          const allCardTypes = response.data.cards; 
          setCardTypes(allCardTypes);

          if(details?.OrderCustomizations?.length > 0 ) {
            const cardIds =
            details?.OrderCustomizations?.map(
              (customization) => customization.cardId
            ) || [];

          const filteredCards = allCardTypes.filter((card) =>
            cardIds.includes(card.id)
          );
          setFilteredCardTypes(filteredCards);
          }else{
          setFilteredCardTypes(response.data.cards);
          }
          
          

        } else {
          console.error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching card types:", error);
      }
    };

    fetchCardTypes();
  }, [navigate, details]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset({
      fullName: "",
      phonenumber: "",
      description: "",
      company: "",
      address: "",
      pincode: "",
      gst: "",
      whatsapp: "",
      linkedin: "",
      website: "",
      profileImage: "",
      coverImage: "",
      cardStyle: "",
      quantity: "",
      price: "",
      email:"",
    });
  };

  const onSubmit = async (data) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/api/admin/single-activation`,
      { ...data, orderId: id },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    reset({
      fullName: "",
      phonenumber: "",
      description: "",
      company: "",
      address: "",
      pincode: "",
      gst: "",
      whatsapp: "",
      linkedin: "",
      website: "",
      profileImage: "",
      coverImage: "",
      cardStyle: "",
      quantity: "",
      price: "",
      email:"",
    });
    (response.status === 200 || response.status === 422) &&
      toast("Success! The data has been added");
    reload();
    setOpen(false);

    // console.log('Response after post',response);
  };

  return (
    <div>
      {/* Display Button */}
      <StyledButton
        variant="outlined"
        text={"Create Profile"}
        onClick={handleClickOpen}
      />
      {/* Dailog Button */}
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Create new profile</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Full name"}
                    name="fullName"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Phone Number"}
                    name="phonenumber"
                    type="tel"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>

              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"Description"}
                    name="description"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>

              {/* Cards dropdown */}
              <Grid item sx={12} md={6}>
                <Item>
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ minWidth: "220px", border: "none" }}
                  >
                    <InputLabel
                      shrink
                      htmlFor="add-sub-category"
                      sx={{
                        fontWeight: 700,
                        fontSize: "20px",
                        color: "#000",
                      }}
                    >
                      Card Style
                    </InputLabel>
                    <Controller
                      control={control}
                      name="cardStyle"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="select-category"
                          placeholder="Card style"
                          id="Add Category"
                          size="small"
                          name="cardStyle"
                          input={<TextField />}
                          value={value}
                          onChange={onChange}
                          sx={
                            errors.cardStyle?.type === "required" && {
                              color: "red",
                              borderRadius: "6px",
                            }
                          }
                        >
                          {filteredCardTypes.map((card) => (
                            <MenuItem key={card.id} value={card.id}>
                              {card.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                    {errors?.cardStyle?.type === "required" && (
                      <FormHelperText sx={{ color: "red" }}>
                        Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Company"}
                    name="company"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              {/* Edit feilds */}
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Email"}
                    name="email"
                    type="email"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>

              {/* <Grid item sx={12} md={6}>
                <Item>
                <StyledTextField
                    label={"Price"}
                    name="price"
                    type="number"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid> */}

              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Address"}
                    name="address"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Pincode"}
                    name="pincode"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>

              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"WhatsApp"}
                    name="whatsapp"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"LinkedIn"}
                    name="linkedin"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"Website"}
                    name="website"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"Profile Image"}
                    name="profileImage"
                    type="text"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"Cover Image"}
                    name="coverImage"
                    type="text"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <StyledButton
              variant="outlined"
              text={"Cancel"}
              onClick={handleClose}
            >
              Cancel
            </StyledButton>
            <StyledButton
              type="submit"
              text={"Submit"}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default SingleProfileCreaetionById;
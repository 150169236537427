import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import TableHead from "@mui/material/TableHead";
import "./Coupons.css";
import { Link } from 'react-router-dom'; 

// Import the Modal component
import Modal from "./Modal";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const Coupons = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  useEffect(() => {
    // Fetch your coupon data here from an API or another source
    // For now, let's create some sample data
    const sampleData = [
      {
        serialNumber: 1,
        couponCode: "#12344",
        type: "Percent",
        value: "20.00%",
        usage: 50,
        status: "Active",
      },

      // {
      //   serialNumber: 2,
      //   couponCode: "COUPON002",
      //   type: "Fixed",
      //   value: 5,
      //   usage: 30,
      //   status: "Inactive",
      // },
      // Add more coupon data as needed
    ];

    setRows(sampleData);
  }, []);

  // Calculate the number of empty rows to add to the table for pagination
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: "#64748b",
      padding: "5px 10px",
      fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      padding: "2px 10px",
    },
  }));

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddCoupon = (couponData) => {
    // Handle the submission of the coupon data (e.g., send it to an API)
    // You can also update your local state with the new coupon
    // For now, let's log the data to the console
    console.log("Submitted Coupon Data:", couponData);
    // Close the modal
    closeModal();
  };

  return (
    <>
      <div className="coupon-container">
        <div className="coupon-header">
          <p>
          <Link
    to="/dashboard"
    style={{
      textDecoration: 'none',
      color: '#454545',
      borderBottom: '1px solid transparent', // Initial border
      transition: 'border-bottom 0.3s ease', // Add smooth transition
    }}
    onMouseOver={(e) => (e.target.style.borderBottom = '1px solid #454545')} // Show underline on hover
    onMouseOut={(e) => (e.target.style.borderBottom = '1px solid transparent')} // Hide underline on mouseout
  >
    Home
  </Link>{" "} / <strong>Coupons</strong>
          </p>
          <div className="coupon-title">
            <h4 className="coupon-heading" style={{ fontSize: '1.5rem' }}>Coupons</h4>
            <button className="coupon-add-btn" onClick={openModal}>
              Add
            </button>
          </div>
        </div>
        <div className="table">
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 800, borderColor: "#E0E0E0" }}
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow style={{ whiteSpace: "nowrap" }}>
                  <StyledTableCell>S.No</StyledTableCell>
                  <StyledTableCell>Coupon Code</StyledTableCell>
                  <StyledTableCell>Type</StyledTableCell>
                  <StyledTableCell>Value</StyledTableCell>
                  <StyledTableCell>Usage</StyledTableCell>
                  <StyledTableCell>Status</StyledTableCell>
                  <StyledTableCell>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : rows
                )?.map((row) => (
                  <TableRow key={row.serialNumber}>
                    <StyledTableCell style={{ padding: "14px 10px" }}>
                      {row.serialNumber}
                    </StyledTableCell>
                    <StyledTableCell>{row.couponCode}</StyledTableCell>
                    <StyledTableCell>
                      <span
                        className="percent-type"
                        id="badge"
                        style={{ backgroundColor: "#1eae53" }}
                      >
                        Percent
                      </span>{" "}
                      <span
                        className="fixed-type"
                        id="badge"
                        style={{ backgroundColor: "#cbda00" }}
                      >
                        Fixed
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>{row.value}</StyledTableCell>
                    <StyledTableCell>{row.usage}</StyledTableCell>
                    <StyledTableCell>
                      <span
                        className="Active"
                        id="badge"
                        style={{ backgroundColor: "#1eae53" }}
                      >
                        Active
                      </span>{" "}
                      <span className="Inactive" id="badge">
                        Inactive
                      </span>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledTableCell>
                        <button className="edit-btn" onClick={openModal}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-pencil-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
                          </svg>{" "}
                        </button>
                        <button className="delete-btn" style={{ color: "red" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                          </svg>
                        </button>
                      </StyledTableCell>
                    </StyledTableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5,10, 20,]}
                    colSpan={7}
                    count={rows?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={"Items per page:"}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </div>
      {/* Add the Modal component */}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSubmit={handleAddCoupon}
      />
    </>
  );
};

export default Coupons;

import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import domtoimage from "dom-to-image";
import { Popper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import Grow from "@mui/material/Grow";
import "../../pages/Dashboard/Dashboard.css";
const DownloadChartHam = ({ chartElement, chartData, csvHeaders }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const createDownloadable = (imageData, name) => {
    const a = document.createElement("a");
    a.href = imageData;
    a.download = name;
    a.click();
  };

  const handleDownloadToPNG = () => {
    if (chartElement) {
      domtoimage.toPng(chartElement.current).then((dataUrl) => {
        createDownloadable(dataUrl, "chart_image.png");
      });
    }
  };

  const handleDownloadToSVG = () => {
    if (chartElement) {
      domtoimage.toSvg(chartElement.current).then((dataUrl) => {
        createDownloadable(dataUrl, "chart_image.svg");
      });
    }
  };

  const handleDownloadToCSV = () => {
    let csvArr = [];

    csvArr.push(csvHeaders);

    const getMonth = (date) => {
      const inputDate = new Date(date);
      const options = { month: "short" };
      return inputDate.toLocaleDateString("en-US", options);
    };

    const tempObj = {};
    chartData.forEach((dataList) => {
      dataList.forEach((dataObject) => {
        if (!tempObj[dataObject.date_trunc]) {
          tempObj[dataObject.date_trunc] = [
            dataObject.count || dataObject.total_amount,
          ];
        } else {
          tempObj[dataObject.date_trunc].push(
            dataObject.count || dataObject.total_amount
          );
        }
      });
    });

    for (const key in tempObj) csvArr.push([getMonth(key), ...tempObj[key]]);

    const csvData = csvArr.map((e) => e.join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "chart_data.csv";
    a.click();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <div>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleToggle}
        sx={{ padding: "0", margin: "0" }}
        ref={anchorRef}
      >
        <MenuIcon sx={{ color: "#6E8192" }} />
      </div>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
        className="popper"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    onClick={(e) => {
                      handleDownloadToSVG();
                      handleClose(e);
                    }}
                  >
                    Download SVG
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    onClick={(e) => {
                      handleDownloadToPNG();
                      handleClose(e);
                    }}
                  >
                    Download PNG
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    onClick={(e) => {
                      handleDownloadToCSV();
                      handleClose(e);
                    }}
                  >
                    Download CSV
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default DownloadChartHam;

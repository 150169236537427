import React, { useEffect, useState } from "react";
import StyledButton from "../../Components/StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputBase from "@mui/material/InputBase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom'; 

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  // border: "2px solid red",
}));

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
}));

const UpdateSubCategory = () => {
  const { id } = useParams();
  const [catValue, setCatValue] = useState("");

  const [subCatValue, setSubCatValue] = React.useState("");
  const [showSubCatError, setShowSubCatError] = React.useState(false);
  const [showCatError, setShowCatError] = React.useState(false);
  const [categories, setCategories] = React.useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) navigate("/sign-in");
        const subCatResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/subcategory/${id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setSubCatValue(subCatResponse?.data?.subcategory?.name);
        setCatValue(subCatResponse?.data?.subcategory?.categoryId);
        const catListResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-all-category`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setCategories(catListResponse?.data);
      } catch (error) {
        if (error?.response?.data?.error === "failed to authenticate token")
          navigate("/sign-in");
      }
    }

    fetchData();
  }, [navigate, id]);

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    if (catValue === "") {
      setShowCatError(true);
    }
    if (subCatValue === "") {
      setShowSubCatError(true);
    } else if (subCatValue !== "" && catValue !== "") {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/update-subcategory/${id}`,
        { name: subCatValue },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      response?.status === 200 && toast("Success! The data has been updated");
      navigate("/sub-category");
    }
  };

  return (
    <div className="blog-container">
      <div className="blog-header">
        <p>
        <Link
    to="/dashboard"
    style={{
      textDecoration: 'none',
      color: '#454545',
      borderBottom: '1px solid transparent', // Initial border
      transition: 'border-bottom 0.3s ease', // Add smooth transition
    }}
    onMouseOver={(e) => (e.target.style.borderBottom = '1px solid #454545')} // Show underline on hover
    onMouseOut={(e) => (e.target.style.borderBottom = '1px solid transparent')} // Hide underline on mouseout
  >
    Home
  </Link>{" "} / <strong>Edit Sub Category</strong>
        </p>
        <h4 style={{ fontSize: '1.5rem' }}>Edit Sub Category</h4>
      </div>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={5}>
            <FormControl
              variant="standard"
              fullWidth
              error={showCatError ? true : false}
            >
              <InputLabel
                shrink
                id="select-category"
                sx={{
                  fontWeight: 700,
                  fontSize: "20px",
                  color: "#000",
                }}
              >
                Select Category
              </InputLabel>
              <Select
                labelId="select-category"
                id="Add Category"
                value={catValue}
                label="Add Category"
                size="small"
                onChange={(e) => setCatValue(parseInt(e.target.value))}
                error={showCatError ? true : false}
                helperText={showCatError && "Category is required"}
                input={<TextField />}
              >
                {categories?.map((category, index) => (
                  <MenuItem key={index} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              {showCatError && (
                <FormHelperText>Category is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Item>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  htmlFor="sub-category"
                  sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
                >
                  Enter Sub Category
                </InputLabel>
                <TextField
                  autoFocus
                  value={subCatValue}
                  error={showSubCatError ? true : false}
                  margin="dense"
                  id="sub-category"
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  helperText={
                    showSubCatError && "Sub Category Name is required"
                  }
                  onChange={(e) => setSubCatValue(e.target.value)}
                />
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={5} sx={{ marginTop: "10px" }}>
            <StyledButton text={"Update"} onClick={handleSubmit} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default UpdateSubCategory;

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";

import PropTypes from "prop-types";
import {
  Stack,
  Typography,
  CircularProgress,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  IconButton,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import axios from "axios";
import CardResDownload from "./CardResDownload";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: 0,
    borderColor: "#E0E3E7",
    fontSize: 12,
    padding: "10px 12px",
    // "&:focus": {
    //   borderColor: "#64748b",
    // },
  },
}));
// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#04459d",
  fontWeight: "bold",
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#04459d",
  "&.Mui-checked": {
    color: "#04459d",
  },
}));

const SearchButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#04459D",
  fontSize: "12px",
  padding: "10px",
  border: 0,
  borderRadius: "14px",
  minWidth: "0 !important",
}));

const CardResDetails = () => {
  const { id } = useParams(); // Access URL parameter
  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [triggerSearch, setTriggerSearch] = useState(false); // State to trigger search
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for dialog visibility
  const [batchNum, setBatchNum] = useState("");
  const navigate = useNavigate();

  const fetchData = async (page, pageSize, query) => {
    setIsLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/sign-in");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/api/cardResource/list?batch=${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: {
            page: page,
            limit: pageSize,
            search: query,
          },
        }
      );
      setRows(response.data.cards);
      setBatchNum(response.data.batch.name);

      setTotalRows(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, pageSize, searchQuery);
  }, [page, pageSize, searchQuery]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectRow = (row) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(row)
        ? prevSelected.filter((item) => item !== row)
        : [...prevSelected, row]
    );
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value.length <= 0) {
      setTriggerSearch(false);
    }
  };
  const handleSearch = () => {
    setTriggerSearch(true);
  };

  return (
    <Stack sx={{ p: 4 }}>
      <Box sx={{ borderBottom: "1px solid #66656536" }}>
        <p>
          <Link
            to="/dashboard"
            style={{
              textDecoration: "none",
              color: "#454545",
              borderBottom: "1px solid transparent", // Initial border
              transition: "border-bottom 0.3s ease", // Add smooth transition
            }}
            onMouseOver={(e) =>
              (e.target.style.borderBottom = "1px solid #454545")
            } // Show underline on hover
            onMouseOut={(e) =>
              (e.target.style.borderBottom = "1px solid transparent")
            } // Hide underline on mouseout
          >
            Home
          </Link>{" "}
          /{" "}
          <Link
            to="/cardRes"
            style={{
              textDecoration: "none",
              color: "#454545",
              borderBottom: "1px solid transparent", // Initial border
              transition: "border-bottom 0.3s ease", // Add smooth transition
            }}
            onMouseOver={(e) =>
              (e.target.style.borderBottom = "1px solid #454545")
            } // Show underline on hover
            onMouseOut={(e) =>
              (e.target.style.borderBottom = "1px solid transparent")
            } // Hide underline on mouseout
          >
            Card Resource
          </Link>{" "}
          / <strong>Batch Details</strong>
        </p>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ margin: "10px 0" }}
        >
          <h4 style={{ fontSize: "1.5rem" }}>Batch Details</h4>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="center"
            sx={{ margin: "10px 0" }}
          >
            <FormControl variant="standard">
              <TextField
                margin="dense"
                id="name"
                label="Title"
                type="text"
                size="small"
                variant="outlined"
                placeholder="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </FormControl>
            <SearchButton variant="contained" onClick={handleSearch}>
              <SearchIcon sx={{ display: "block", width: "24px" }} />
            </SearchButton>
          </Stack>
        </Stack>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mt={2}
      >
        <p>Card Resources of batch <strong>{batchNum}</strong></p>
        <Stack direction="row" spacing={2}>
          {/* {selectedRows.length > 0 && (
          <>
            <DeleteMultipleDialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
              selectedOrders={selectedRows.map((row) => row.id)}
              handleDeleteMultipleFn={handleDeleteSelected}
              types={selectedRows.length > 1 ? "Resource Cards" : "Resource card"}
            />
          </>
        )} */}
        <CardResDownload id={id} source={'CardDetails'} />
        </Stack>
      </Stack>

      {isLoading ? (
        <Stack
          mt={2}
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "60vh",
            zIndex: 1000,
            backgroundColor: "white",
            borderRadius: 3,
          }}
        >
          <CircularProgress sx={{ color: "#04459d" }} size={60} />
        </Stack>
      ) : (
        <div className="table">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <StyledCheckbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < rows.length
                      }
                      checked={
                        rows.length > 0 && selectedRows.length === rows.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <StyledTableCell>ID</StyledTableCell>
                  <StyledTableCell>Batch</StyledTableCell>
                  <StyledTableCell>Unique Code</StyledTableCell>
                  <StyledTableCell>URL</StyledTableCell>
                  {/* <StyledTableCell>Actions</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id} selected={selectedRows.includes(row)}>
                    <TableCell padding="checkbox">
                      <StyledCheckbox
                        checked={selectedRows.includes(row)}
                        onChange={() => handleSelectRow(row)}
                      />
                    </TableCell>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{batchNum}</TableCell>
                    <TableCell>{row.uniqueCode}</TableCell>

                    <TableCell>{row.url}</TableCell>
                    <TableCell>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"center"}
                      >
                        {/* <DeleteOneDailog
                      orderId={row.id}
                      handleDeleteOne={handleDelete}
                      types={"review card"}
                    /> */}
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={totalRows}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              ActionsComponent={TablePaginationActions}
            />
          </TableContainer>
        </div>
      )}
    </Stack>
  );
};

export default CardResDetails;

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputBase from "@mui/material/InputBase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(2),
  },

  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
}));

const ChangePlanDialog = ({ reload, selected }) => {
  const [open, setOpen] = React.useState(false);
  const [plan, setPlan] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShowError(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    if (plan === "") {
      setShowError(true);
    } else if (plan !== "") {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/change-plan-multiple-users`,
        {
          ids: selected,
          planType: plan,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      reload();
      response.status === 200 && toast("Success! Plan changed successfully");
      setOpen(false);
    }
  };

  return (
    <div>
      <StyledButton text={"Change Plan"} onClick={handleClickOpen} />
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Subscription</DialogTitle>
        <DialogContent>
          <FormControl
            variant="standard"
            fullWidth
            error={showError ? true : false}
          >
            <InputLabel
              shrink
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#000",
              }}
              htmlFor="select-category"
            >
              Select Plan
            </InputLabel>
            <Select
              labelId="select-category"
              id="Add Category"
              value={plan}
              label="Add Category"
              size="small"
              onChange={(e) => setPlan(parseInt(e.target.value))}
              error={showError ? true : false}
              helperText={showError && "Category is required"}
              input={<TextField />}
            >
              <MenuItem value={1}>Pro</MenuItem>
              <MenuItem value={3}>Standard</MenuItem>
            </Select>
            {showError && <FormHelperText>Plan is Required</FormHelperText>}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Cancel"}
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton text={"Submit"} onClick={handleSubmit}>
            Subscribe
          </StyledButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ChangePlanDialog;

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import TableHead from "@mui/material/TableHead";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import StyledButton from "../../Components/StyledButton";
import { Link } from "react-router-dom";
import avatar from "../../assets/images/avatar.jpg";
import ChangePlanDialog from "../../Components/AppUsers/ChangePlanDialog";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Loader from "../../Components/Loader";
import { utils, writeFile } from "xlsx";
import DeleteMultipleDialog from "../../Components/DeleteDailogs/DeleteMultipleDialog";
import DeleteOneDailog from "../../Components/DeleteDailogs/DeleteOneDailog";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#64748b",
    padding: "5px 10px",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px 10px",
  },
}));

const SearchButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#04459D",
  fontSize: "12px",
  padding: "10px",
  border: 0,
  borderRadius: "14px",
  minWidth: "0 !important",
}));

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: 0,
    borderColor: "#E0E3E7",
    fontSize: 12,
    padding: "10px 12px",
    // "&:focus": {
    //   borderColor: "#64748b",
    // },
  },
}));

const Customers = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [selected, setSelected] = React.useState([]);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleReload = () => setReload(!reload);

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     handleSearch();
  //   }
  // };
  const getUsers = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");

    const queryParams = {
      search: searchInput,
      // Add more query parameters as needed
    };

    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/api/admin/list-users`,
      {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    setRows(response?.data?.users);
  };

  const handleSearch = useCallback(() => {
    setPage(0);
    getUsers();
  }, [getUsers]);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const authToken = localStorage.getItem("authToken");
        if (!authToken) navigate("/sign-in");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/list-users`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        response.status === 200 && setIsLoading(false);
        setRows(response?.data?.users);
      } catch (error) {
        if (error?.response?.data?.error === "failed to authenticate token")
          navigate("/sign-in");
      }
    }

    fetchData();
  }, [navigate, reload]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const AWS_S3_BUCKET_URL = process.env.REACT_APP_AWS_S3_BUCKET_URL;
  const handleExport = () => {
    const data = rows.map((row) => {
      return {
        "Full Name": row.fullName,
        Email: row.email,
        "Phone Number": row.phonenumber,
        "Customer Id": row.customerId,
      };
    });

    // Create a worksheet from the data
    const ws = utils.json_to_sheet(data);

    // Create a workbook
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");

    writeFile(wb, "user_data.xlsx");
  };

  const handleDeleteOne = async (id) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) navigate("/sign-in");
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/delete-user/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      response.status === 200 && handleReload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteMultipleFn = async (userIds) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) navigate("/sign-in");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/delete-multiple-users`,
        {
          ids: userIds,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setSelected([])
      response.status === 200 && handleReload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          margin: "20px 30px",
        }}
      >
        <Box sx={{ borderBottom: "1px solid #66656536" }}>
          <p>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Home
            </Link>{" "}
            / <strong>Customers</strong>
          </p>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ margin: "10px 0" }}
          >
            <h4 style={{ fontSize: "1.5rem" }}>Customers</h4>

            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              // spacing={2}
              sx={{ margin: "10px 0" }}
            >
              <FormControl variant="standard">
                <TextField
                  value={searchInput}
                  margin="dense"
                  id="name"
                  label="Title"
                  type="text"
                  size="small"
                  variant="outlined"
                  placeholder="Search"
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </FormControl>
              <SearchButton variant="contained" onClick={handleSearch}>
                <SearchIcon sx={{ display: "block", width: "24px" }} />
              </SearchButton>
            </Stack>
          </Stack>
        </Box>
        <Box sx={{ flexGrow: 1, marginTop: 2 }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            // sx={{ paddingBottom: "15px" }}
          >
            <Stack direction="row" spacing={1}>
              <ChangePlanDialog reload={handleReload} selected={selected} />

              <StyledButton
                text={"Export"}
                variant="outlined"
                onClick={handleExport}
              />
              {selected.length > 0 && (
                  <DeleteMultipleDialog
                  selectedOrders={selected}
                  handleDeleteMultipleFn={handleDeleteMultipleFn}
                  types={selected.length > 1 ? 'users' : 'user'}
                />
              )}
            
            </Stack>
          </Stack>
          <div className="table">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 800, borderColor: "#E0E0E0" }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        style={{ color: "#04459d" }}
                        color="primary"
                        // indeterminate={
                        //   selected?.length > 0 &&
                        //   selected?.length < rows?.length
                        // }
                        checked={
                          rows?.length > 0 && selected?.length === rows?.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                    <StyledTableCell>User</StyledTableCell>
                    <StyledTableCell>Cust.ID</StyledTableCell>
                    <StyledTableCell>Contact</StyledTableCell>
                    <StyledTableCell>Subscription</StyledTableCell>
                    <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                      Product Type
                    </StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(rowsPerPage > 0
                    ? rows?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : rows
                  )?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        key={row.id}
                        // onClick={(event) => handleClick(event, row.id)}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        // selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            style={{ color: "#04459d" }}
                            onClick={(event) => handleClick(event, row.id)}
                            color="primary"
                            checked={isItemSelected}
                            selected={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <StyledTableCell style={{ width: 140 }}>
                          <Box
                            sx={{
                              display: "flex",
                              gridGap: "10px",
                              alignItems: "center",
                            }}
                          >
                            <Box sx={{ position: "relative" }}>
                              <img
                                src={
                                  row.profileImage != null
                                    ? `https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${row.profileImage}`
                                    : avatar
                                }
                                alt=""
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "50%",
                                }}
                              />
                              {row.UserSubscription?.Card?.CardType?.name ===
                                "Pro" && (
                                <LocalPoliceIcon
                                  sx={{
                                    fontSize: "12px",
                                    color: "#FFD700",
                                    position: "absolute",
                                    top: "29px",
                                    left: "28px",
                                    zIndex: 100,
                                  }}
                                />
                              )}
                            </Box>
                            <Box>
                              <Box sx={{ fontWeight: 700 }}>{row.fullName}</Box>
                              <Box sx={{ fontSize: "13px" }}>{row.email}</Box>
                            </Box>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 80 }}>
                          {row.customerId}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 100 }}>
                          {row.phonenumber}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{ width: 120, whiteSpace: "nowrap" }}
                        >
                          {" "}
                          {/* Increase the width */}
                          {row.UserSubscription?.Card?.CardType?.name}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 80 }}>
                          {row.UserSubscription?.Card?.type}
                        </StyledTableCell>
                        <TableCell
                          style={{
                            width: 80,
                            textAlign: "center",
                            padding: "2px 10px",
                            color: "#64748b",
                          }}
                        >
                          <Link to={`/customer-details/${row?.id}`}>
                            <VisibilityIcon
                              fontSize={"small"}
                              sx={{
                                marginRight: "10px",
                                color: "#64748b",
                                cursor: "pointer",
                              }}
                            />
                          </Link>
                          {/* <DeleteIcon
                            fontSize={"small"}
                            color={"error"}
                            onClick={() => handleDeleteOne(row.id)}
                            sx={{ cursor: "pointer" }}
                          /> */}
                          <DeleteOneDailog
                            orderId={row?.id}
                            handleDeleteOne={handleDeleteOne}
                            types={'user'}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      colSpan={6}
                      count={rows?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={"Items per page:"}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Customers;

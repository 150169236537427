import React, { useEffect, useState } from "react";
import { Navigate, Link } from "react-router-dom";
const SignOut = () => {
  localStorage.removeItem("authToken");
  const [counter, setCounter] = useState(5);
  useEffect(() => {
    counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  });
  return counter <= 0 ? (
    <Navigate to="/sign-in" />
  ) : (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#eee",
        }}
      >
        <div>
          <div
            style={{
              width: "450px",
              height: "200px",
              padding: "40px",
              textAlign: "center",
              backgroundColor: "#fff",
              borderRadius: "10px",
            }}
          >
            <div style={{ paddingBottom: "20px" }}>
              <h2 style={{ fontWeight: 700 }}>You have signed out!</h2>
              <p style={{ fontWeight: 400 }}>
                Redirecting in {counter} seconds
              </p>
            </div>
            <div>
              Go to <Link to="/sign-in">Sign in</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignOut;

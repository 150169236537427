import React, { useRef, useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import StyledButton from "../../Components/StyledButton";
import { QRCodeSVG } from "qrcode.react";
import html2canvas from "html2canvas";
import QRGenList from "./QRGenList";
import QRCreation from "./QRCreation";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import axios from 'axios';
const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: 0,
    borderColor: "#E0E3E7",
    fontSize: 12,
    padding: "10px 12px",
    // "&:focus": {
    //   borderColor: "#64748b",
    // },
  },
}));
const SearchButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#04459D",
  fontSize: "12px",
  padding: "10px",
  border: 0,
  borderRadius: "14px",
  minWidth: "0 !important",
}));
const QRGen = () => {
  const [searchQuery, setSearchQuery] = useState(""); // Search input state
  const [triggerSearch, setTriggerSearch] = useState(false); // State to trigger search

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if(event.target.value.length <= 0 ){
      setTriggerSearch(false);
    }
  };

  const handleSearch = () => {
    setTriggerSearch(true); 
  };

  return (
    <div>
      <Box
        sx={{
          margin: "20px 30px",
        }}
      >
        <Box sx={{ borderBottom: "1px solid #66656536" }}>
          <p>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Home
            </Link>{" "}
            / <strong>QR Generation</strong>
          </p>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ margin: "10px 0" }}
          >
            <h4 style={{ fontSize: "1.5rem" }}>QR Generation</h4>
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              sx={{ margin: "10px 0" }}
            >
              <FormControl variant="standard">
                <TextField
                  margin="dense"
                  id="name"
                  label="Title"
                  type="text"
                  size="small"
                  variant="outlined"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </FormControl>
              <SearchButton variant="contained" onClick={handleSearch}>
                <SearchIcon sx={{ display: "block", width: "24px" }} />
              </SearchButton>
            </Stack>
          </Stack>
        </Box>
        <QRGenList searchQuery={triggerSearch ? searchQuery : ""} />
      </Box>
    </div>
  );
};

export default QRGen;
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import StyledButton from "../StyledButton";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";

const ColorButton = styled(Button)(
  ({ theme, variant, setcolor, hovercolor }) => ({
    color: variant === "outlined" ? setcolor : "#fff",
    backgroundColor: variant === "outlined" ? "#fff" : setcolor,
    fontSize: "10px",
    padding: "6px 6px",
    border: `1px solid ${setcolor}`,
    borderRadius: "8px",
    width: "100px", 
    "&:hover": {
      backgroundColor: variant === "outlined" ? "#fff" : hovercolor,
      color: variant === "outlined" ? hovercolor : "#fff",
    },
  })
);


const StyledBtn = ({
  variant = "contained",
  text,
  onClick,
  color = "#04459d",
  hovercolor = "#286090",
}) => {
  return (
    <ColorButton
      onClick={onClick}
      variant={variant}
      setcolor={color}
      hovercolor={hovercolor}
    >
      {text}
    </ColorButton>
  );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(2),
  },

  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
}));

const UpdateStatusDialog = ({ reload, id, presentStatus, statusList }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState(presentStatus);
  const [showCatError, setShowCatError] = React.useState(false);
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShowCatError(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    if (selectedStatus === "") {
      setShowCatError(true);
    } else if (selectedStatus !== "") {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/update-order-status/${id}`,
        {
          status: selectedStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      reload();
      response?.status === 200 && setOpen(false);
    }
  };

  return (
    <div>
      <StyledBtn text={"Update Status"} onClick={handleClickOpen} />
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            variant="standard"
            error={showCatError ? true : false}
          >
            <InputLabel
              shrink
              htmlFor="select-category"
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#000",
              }}
              id="select-category"
            >
              Select an option
            </InputLabel>
            <Select
              labelId="select-category"
              id="Add Category"
              value={selectedStatus}
              size="small"
              onChange={(e) => setSelectedStatus(parseInt(e.target.value))}
              error={showCatError ? true : false}
              helperText={showCatError && "Category is required"}
              input={<TextField />}
            >
              {statusList?.map((category, index) => (
                <MenuItem key={index} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {showCatError && (
              <FormHelperText>Option is required</FormHelperText>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Cancel"}
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton text={"Submit"} onClick={handleSubmit}>
            Subscribe
          </StyledButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default UpdateStatusDialog;

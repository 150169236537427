import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  LinearProgress, Typography
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Slide from "@mui/material/Slide";

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
  "& .Mui-error": {
    color: "#000",
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "20px",
    padding: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateCard = ({ details, reload }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filteredCardTypes, setFilteredCardTypes] = useState([]);
  const [loading,setLoading] = useState(false);
  const [qtyError,setQtyError] = useState('');

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    getCardData();
  }, []);
  const handleFocus = () => {
    setQtyError(''); // Reset the error when the field is focused
  };

  const getCardData = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/sign-in");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/getCardTypes`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFilteredCardTypes(response.data.cards);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    reset({
      quantity: "",
      cardStyle: "",
    });
    setOpen(false);
    setLoading(false);
    setQtyError('')
  };

  const onSubmit = async (data) => {
    const { quantity, cardStyle } = data;
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("No auth token found, navigating to sign-in.");
        navigate("/sign-in");
        return;
      }
      setLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/generate-cards`,
        {
          quantity: quantity,
          cardId: cardStyle,
          orderId: details.orderId,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      setLoading(false)
      toast.success("Card Created Successfully!");
      reload();
      handleClose();
    } catch (error) {
      if(error.response.data === 'Quantity exceeds the limit'){
        toast.error("Quantity exceeds the limit");
        setQtyError(`Please select a quantity not exceeding ${details.quantity - details.UserSubscriptions.length}`);
      }
    } finally{
      setLoading(false)
    }
  };


  const handleCardPurhcaseCreateCard = async() => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("No auth token found, navigating to sign-in.");
        navigate("/sign-in");
        return;
      }
      setLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/generate-cards`,
        {
          orderId: details.orderId,
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      toast.success("Card Created Successfully!");

      reload();
    } catch (error) {
     console.error(error)
    } finally{
      setLoading(false)

    }
  }

  return (
    <div>
      <StyledButton
        text={loading ? 'Creating...':'Create Card'}
        variant="outlined"
        onClick={details.type === 'Bulk Purchase' ? handleClickOpen : handleCardPurhcaseCreateCard}
        disabled={loading}
      />

      <StyledDialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>Create Card</DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            variant="standard"
            sx={{ minWidth: "220px", border: "none", marginTop: 2 }}
          >
            <InputLabel
              shrink
              htmlFor="quantity"
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#000",
              }}
            >
              Quantity
            </InputLabel>
            <Controller
              control={control}
              name="quantity"
              rules={{ required: true, min: 1 }}
              render={({ field: { onChange, value } }) => (
                <TextField
                onFocus={handleFocus}
                  placeholder="Enter Quantity"
                  type="number"
                  value={value || ""}
                  onChange={(e) => onChange(Number(e.target.value) || 0)} // Convert to number
                  sx={
                    errors.quantity?.type === "required" && {
                      border: "1px solid red",
                      borderRadius: "6px",
                    }
                  }
                />
              )}
            />

            {errors?.quantity?.type === "required" && (
              <FormHelperText sx={{ color: "red" }}>
                Quantity is required
              </FormHelperText>
            )}
            {errors?.quantity?.type === "min" && (
              <FormHelperText sx={{ color: "red" }}>
                Quantity must be at least 1
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            fullWidth
            variant="standard"
            sx={{ minWidth: "220px", border: "none", marginTop: 2 }}
          >
            <InputLabel
              shrink
              htmlFor="add-sub-category"
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#000",
              }}
            >
              Card Style
            </InputLabel>
            <Controller
              control={control}
              name="cardStyle"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Select
                  labelId="select-card-style"
                  placeholder="Card Style"
                  size="small"
                  name="cardStyle"
                  input={<TextField />}
                  value={value || ""}
                  onChange={onChange}
                  sx={
                    errors.cardStyle?.type === "required" && {
                      border: "1px solid red",
                      borderRadius: "6px",
                    }
                  }
                >
                  {filteredCardTypes.map((card) => (
                    <MenuItem key={card.id} value={card.id}>
                      {card.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.cardStyle?.type === "required" && (
              <FormHelperText sx={{ color: "red" }}>
                Card Style is required
              </FormHelperText>
            )}
          </FormControl>
          <Typography sx={{color:'red',fontSize:12,mt:2}}>{qtyError}</Typography>
        </DialogContent>
        {loading && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', flexDirection: 'column' }}>
            <LinearProgress  style={{ width: '100%', backgroundColor: '#286090', marginBottom: '10px' }}
              sx={{
                '& .MuiLinearProgress-barColorPrimary': {
                  backgroundColor: '#c8e2f7',
                },
              }} />
          </div>
        )}
        <DialogActions>
          <StyledButton
            variant="outlined"
            text="Cancel"
            onClick={handleClose}
          />
          <StyledButton text={loading ? 'Creating' : 'Create'} onClick={handleSubmit(onSubmit)} disabled={loading} />
        </DialogActions>
      </StyledDialog>
    </div>
  );
};

export default CreateCard;

import React, { useState } from "react";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [company, setCompany] = useState("");
  // const [termsPolicy, setTermsPolicy] = useState(false);
  // const [error, setError] = useState("");

  // const emailError = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [rememberMe, setRememberMe] = useState(false);
  const [emailError, setEmailError] = useState("");

  // const handleRememberMeChange = () => {
  //   setRememberMe(!rememberMe);
  // };

  // const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  //   const navigate = useNavigate();

  const validateEmail = () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(email)) {
      setEmailError("Please enter a valid email address.");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Add validation for terms and policy checkbox
    // if (!termsPolicy) {
    //   setError("Please accept the terms and policy.");
    //   return;
    // }


    if (!validateEmail()) {
      return;
    }

 // Make the API request to reset the password
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/auth/forgot-password`,
        {
          email,
        }
      );

      if (response.status === 200) {
        // navigate("/dashboard");
        toast.success("Password reset email sent successfully.");
      } else {
        // Handle other response statuses or errors from the API
        console.log("API request failed with status: " + response.status);
        if (response.status === 404) {
          toast.error("Email not found.");
        } else {
          toast.error("An error occurred while sending the reset email.");
        }
      }
    } catch (error) {
      console.error("API request error", error);
      // setError("An error occurred while signing up.");
      toast.error("An error occurred while sending the reset email.")
    }
  };

  const verticalLineStyle = {
    borderLeft: "2px solid lightgray",
    height: "80%",
    position: "absolute",
    left: "-10px",
  };

  return (
    <div className="wrapper">
      <ToastContainer />
      <nav className="navbar navbar-expand-lg fixed">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              className="header-logo img-fluid"
              src={require("../../assets/images/c.png")}
              alt="Logo"
              style={{ maxWidth: "170px" }}
            />
          </a>
          <button
            className={`navbar-toggler ${isMenuOpen ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/faq"
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    fontWeight: "normal", // Add this line
                  }}
                >
                  {!isMenuOpen && <span style={verticalLineStyle}></span>}
                  Need any help?
                </a>
              </li>
              {/* Add more menu items here */}
            </ul>
          </div>
        </div>
      </nav>

      <div className="container border border">
        <section className="login">
          <div className="row align-items-center">
            <div
              className="col-lg-6  d-lg-block  login-left d-none d-sm-block"
              style={{ marginTop: "110px" }}
            >
              <img
                src={require("../../assets/images/login-group.png")}
                className="img-fluid d-none d-lg-block d-block mx-auto login-image"
                alt="Login"
                // style={{ height: "500px" }}
                style={{ maxWidth: "600px", width: "500px" }}
              />
            </div>

            <div className="col-lg-6 login-right">
              <div className="login-account">
                <div>
                  <img
                    src={require("../../assets/images/logo-icon.png")}
                    className="footer-logo d-block mx-auto mt-5"
                    alt="Logo Icon"
                    style={{
                      maxHeight: "100%",
                      width: "50px",
                      marginBottom: "30px",
                    }}
                  />
                  <div className="text-center">
                    <h2 style={{ color: "#04459D", fontWeight: "bolder",fontSize:'2rem' }}>
                      Forgot Password?
                    </h2>
                    <p className="d-flex justify-content-center">
                      Fill the form to reset your password
                    </p>
                  </div>
                  <div className="text-center"></div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row justify-content-center">
                    {/* <div className="col align-self-center"> */}

                    <div className="row justify-content-center">
                      <div className="col-8 ">
                        {/* {error && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginBottom: "4px",
                              textAlign: "center",
                            }}
                          >
                            {error}
                          </p>
                        )} */}
                        <label for="exampleInputEmail1">Email address</label>
                        <input
                          id="email"
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ fontSize: "14px", height: "45px" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "4px",
                            }}
                          >
                            {emailError}
                          </p>
                        )}
                      </div>
                      {/* </div> */}
                    </div>

                    <div
                      className="row justify-content-center"
                      style={{
                        marginBottom: "10px",
                        marginTop: "10px",
                        // paddingLeft: "30px",
                      }}
                    >
                      <div className="col-8">
                        <button
                          id="login-button"
                          className="col-12 btn btn-primary"
                          type="submit"
                          style={{ padding: "5px" }}
                        >
                          Send Reset Link
                        </button>
                      </div>
                    </div>
                    <p className="d-flex justify-content-center">
                      Return to
                      <p
                        className="d-flex signup"
                        style={{ marginLeft: "10px" }}
                      >
                        <a className=" fw-bold" href="/sign-in" style={{textDecoration:'none'}}>
                          Sign in
                        </a>
                      </p>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="footer" style={{ marginTop: "30px" }}>
        <div className="container">
          <div className="">
            {/* Logo */}
            <img
              src={require("../../assets/images/c.png")}
              alt="Connct Logo"
              style={{ maxWidth: "200px" }}
            />
            {/* Tagline */}
            <div style={{ margin: "10px 0", fontSize: "20px" }}>
              dont just say hello, just 👋 Connct
            </div>
            {/* Horizontal Line */}
            <hr style={{ backgroundColor: "lightgray", height: "2px" }} />
            {/* Copyright Notice */}
            <div style={{ margin: "10px 0" }}>
              Copyright © 2022. All rights reserved Connct
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

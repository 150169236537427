import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Stack,
  Typography,
  CircularProgress,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  IconButton,
  Box
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import axios from "axios";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import QRCreation from "./QRCreation";
import DeleteMultipleDialog from "../../Components/DeleteDailogs/DeleteMultipleDialog";
import DeleteOneDailog from "../../Components/DeleteDailogs/DeleteOneDailog";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// Styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#04459d',
  fontWeight: 'bold',
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: '#04459d',
  '&.Mui-checked': {
    color: '#04459d',
  },
}));

const QRGenList = ({ searchQuery }) => {
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false); // State for dialog visibility
  const navigate = useNavigate();

  const fetchData = async (page, pageSize, query) => {
    setIsLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/sign-in");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/api/reviewCard/list`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: {
            page: page,
            limit: pageSize,
            search: query,
          },
        }
      );
      setRows(response.data.reviewCards);
      setTotalRows(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, pageSize, searchQuery);
  }, [page, pageSize, searchQuery]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectRow = (row) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(row)
        ? prevSelected.filter((item) => item !== row)
        : [...prevSelected, row]
    );
  };

  const handleDeleteSelected = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/sign-in");
        return;
      }
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/v1/api/reviewCard/bulk-delete`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: { ids: selectedRows.map((row) => row.id) },
        }
      );
      setOpenDeleteDialog(false); // Close dialog after deletion
      setSelectedRows([]); // Clear selected rows
      fetchData(page, pageSize, searchQuery); // Refresh data
    } catch (error) {
      console.error("Error while deleting multiple items:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/sign-in");
        return;
      }
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/v1/api/reviewCard/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      fetchData(page, pageSize, searchQuery);
    } catch (error) {
      console.error(`Error while deleting single data:`, error);
    }
  };

  const handleView = (id) => {
    navigate(`/qrdetails/${id}`);
  };

  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        mt={2}
      >
        <p>QR Generated list</p>
        <Stack direction="row" spacing={2}>
          {selectedRows.length > 0 && (
            <>
              <DeleteMultipleDialog
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                selectedOrders={selectedRows.map((row) => row.id)}
                handleDeleteMultipleFn={handleDeleteSelected}
                types={selectedRows.length > 1 ? "review cards" : "review card"}
              />
            </>
          )}
          <QRCreation onCreate={() => fetchData(page, pageSize, searchQuery)} />
        </Stack>
      </Stack>

      {isLoading ? (
        <Stack
          mt={2}
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "60vh",
            zIndex: 1000,
            backgroundColor: "white",
            borderRadius:3
          }}
        >
          <CircularProgress sx={{color:'#04459d'}} size={60} />
        </Stack>
      ) : (
        <div className="table">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <StyledCheckbox
                    indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
                    checked={rows.length > 0 && selectedRows.length === rows.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <StyledTableCell>ID</StyledTableCell>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>URL</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} selected={selectedRows.includes(row)}>
                  <TableCell padding="checkbox">
                    <StyledCheckbox
                      checked={selectedRows.includes(row)}
                      onChange={() => handleSelectRow(row)}
                    />
                  </TableCell>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.url}</TableCell>
                  <TableCell>
                    <Stack direction={"row"} spacing={2} alignItems={'center'}>
                      <VisibilityIcon
                        onClick={() => handleView(row.id)}
                        sx={{ color: "grey", cursor: 'pointer' }}
                      />
                      <DeleteOneDailog
                        orderId={row.id}
                        handleDeleteOne={handleDelete}
                        types={"review card"}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={totalRows}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            ActionsComponent={TablePaginationActions}
          />
        </TableContainer>
        </div>
      )}
    </div>
  );
};

export default QRGenList;

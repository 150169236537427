import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  Chip,
  styled,
} from "@mui/material";
import QRCode from "qrcode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";
import StyledButton from "../../Components/StyledButton";
import { useNavigate } from "react-router-dom";
import InputBase from "@mui/material/InputBase";

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
  "& .Mui-error": {
    color: "#000",
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "20px",
    padding: theme.spacing(2),
  },
}));

const CardResCreation = ({fetchData}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [numOfQRCodes, setNumOfQRCodes] = useState("");
  const [batchNumber, setBatchNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const generateRandomBatchString = () => {
    const chars = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
    let randomString = "B-";
    for (let i = 0; i < 5; i++) {
      randomString += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return randomString;
  };


  const handleClickOpen = async () => {
    let randomBatchString = generateRandomBatchString();
    console.log("Generated initial batch string:", randomBatchString);

    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        console.error("No auth token found, navigating to sign-in.");
        navigate("/sign-in");
        return;
      }

      const makeRequest = async (batch) => {
        return await axios.post(
          `${process.env.REACT_APP_BASE_URL}/v1/api/cardResource/check-batch`,
          { batch },
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
      };

      try {
        let response = await makeRequest(randomBatchString);
        console.log("Response data:", response.data);
      } catch (error) {
        console.error("Error on first attempt:", error.response?.data);

        if (error.response?.data === "Batch is already created") {
          console.log("Batch exists, generating new batch string");

          randomBatchString = generateRandomBatchString();
          console.log("Generated new batch string:", randomBatchString);

          try {
            let response = await makeRequest(randomBatchString);
            console.log("Response data for new batch:", response.data);
          } catch (secondError) {
            console.error(
              "Error on second attempt:",
              secondError.response?.data
            );
            return;
          }
        } else {
          throw error;
        }
      }
    } catch (error) {
      console.error("Final error:", error);
      return;
    }
    setBatchNumber(randomBatchString);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setNumOfQRCodes("");
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
  
    // Allow empty string (for clearing input), but prevent 0 and negative numbers
    if (value === "" || value > 0) {
      setNumOfQRCodes(value);
    }
  };
  
  

  const handleGenerate = async () => {
    if (numOfQRCodes !== "") {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
          console.error("No auth token found, navigating to sign-in.");
          navigate("/sign-in");
          return;
        }

        setLoading(true);
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/v1/api/cardResource/bulkCreate`,
          {
            batch: batchNumber,
            quantity: numOfQRCodes,
          },
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
        const responseData = response.data.createdCards;
        fetchData();
        const zip = new JSZip();
        const imgFolder = zip.folder(batchNumber);

        const generatedCodes = responseData.map((data) => {
          const { uniqueCode, qrCode, url } = data;
          const base64String = qrCode.split(",")[1];

          const qrCodeBlob = dataURLToBlob(qrCode);
          imgFolder.file(`${uniqueCode}.png`, qrCodeBlob);

          return {
            Serial: data.id,
            RandomString: uniqueCode,
            URL: url,
            Base64: `data:image/png;base64,${base64String}`,
          };
        });

        const worksheet = XLSX.utils.json_to_sheet(generatedCodes);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "QR Codes");

        const excelBinaryString = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "binary",
        });
        const excelBlob = new Blob([s2ab(excelBinaryString)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        zip.file(`${batchNumber}.xlsx`, excelBlob);

        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, `${batchNumber}.zip`);
        });

        handleClose();
        setLoading(false);
        toast("Card Resource Created Successfully!!");
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }
  };

  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };


  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  return (
    <div>
      <StyledButton text={"Create new"} onClick={handleClickOpen} />

      <StyledDialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>QR Resource Creation</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="How many QR codes do you want to generate?"
            type="number"
            fullWidth
            value={numOfQRCodes}
            onChange={handleInputChange}
            placeholder="Enter Quantity"
          />
          <Typography marginTop={2}>
            Batch Number:{" "}
            <Chip label={batchNumber} color="success" variant="filled" />
          </Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton
            text={"Cancel"}
            variant="outlined"
            onClick={handleClose}
          />
          {!loading ? (
            <StyledButton
              variant="contained"
              text={"Generate"}
              onClick={handleGenerate}
            />
          ) : (
            <Button variant="contained" color="primary">
              <CircularProgress color="inherit" size={25} />
            </Button>
          )}
        </DialogActions>
      </StyledDialog>
    </div>
  );
};

export default CardResCreation;

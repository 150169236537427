import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import Slide from '@mui/material/Slide';
import { styled } from "@mui/material/styles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: "20px", 
      padding: theme.spacing(2),
    },
  }));

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const DeleteOneDailog = ({ orderId, handleDeleteOne,types }) => {
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDialog = () => {
    setOpenDelete(true);
  };

  const handleCloseDialog = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    if (handleDeleteOne) {
      handleDeleteOne(orderId); // Pass the orderId to the parent's delete function
    }
    setOpenDelete(false);
  };

  return (
    <>
      <DeleteIcon
        fontSize={"small"}
        color={"error"}
        onClick={handleOpenDialog}
        sx={{ cursor: "pointer" }}
      />
      <StyledDialog 
        open={openDelete}
        TransitionComponent={Transition}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="error">
          {`Delete ${types}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this {types}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Cancel"}
            onClick={handleCloseDialog}
            color="primary"
          />
          <StyledButton
            text={"Delete"}
            color="#c9302c"
            hovercolor="#d9534f"
            onClick={handleDelete}
            autoFocus
          />
        </DialogActions>
      </StyledDialog >
    </>
  );
};

export default DeleteOneDailog;

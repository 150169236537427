import React, { useState, useEffect } from "react";
import StyledButton from "../../Components/StyledButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CircularProgress } from "@mui/material";

const CardResDownload = ({ id, source }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleDownload = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/sign-in");
        return;
      }
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/api/cardResource/list?batch=${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          params: {
            page: 0,
            limit: 0,
          },
        }
      );
      const responseData = response.data.cards;
      const batchNumber = response.data.batch.name;
      const zip = new JSZip();
      const imgFolder = zip.folder(batchNumber);

      const generatedCodes = responseData.map((data) => {
        const { uniqueCode, qrCode, url } = data;
        const base64String = qrCode.split(",")[1];

        const qrCodeBlob = dataURLToBlob(qrCode);
        imgFolder.file(`${uniqueCode}.png`, qrCodeBlob);

        return {
          Serial: data.id,
          RandomString: uniqueCode,
          URL: url,
          Base64: `data:image/png;base64,${base64String}`,
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(generatedCodes);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "QR Codes");

      const excelBinaryString = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });
      const excelBlob = new Blob([s2ab(excelBinaryString)], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      zip.file(`${batchNumber}.xlsx`, excelBlob);

      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `${batchNumber}.zip`);
      });
      toast.success("Card Resource Downloaded Successfully!!");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const dataURLToBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  return (
    <div>
      {source === "CardDetails" ? (
        <StyledButton text={loading ? "Downloading..." : "Download"} onClick={handleDownload} disabled={loading} />
      ) : source === "batch" ? (
        <>
          {loading ? (
            <CircularProgress size={16} sx={{ color: "grey", mt: 0.5 }} />
          ) : (
            <DownloadIcon
              sx={{ color: "grey", cursor: "pointer" }}
              onClick={handleDownload}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CardResDownload;

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import sampleFile from "../../../src/assets/images/test-file.csv";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(1),
  },

  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const UploadDialog = ({ reload }) => {
  const [open, setOpen] = React.useState(false);
  const [fileData, setFileData] = React.useState([]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/plain": [".csv"],
      "application/vnd.ms-excel": [".csv"],
    },
    maxFiles: 1,
    onDrop: (files) => setFileData(files),
  });

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    const formData = new FormData();
    formData.append("file", fileData[0]);
    if (fileData.length <= 0) {
      toast("Please add the data");
    } else {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/bulk-activation`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      response.status === 200 && toast("Success! Activation successful");
      setOpen(false);
      reload();
    }
  };

  return (
    <div>
      <StyledButton text={"Upload"} onClick={handleClickOpen} />
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Bulk Exports</DialogTitle>
        <DialogContent>
          <section className="container">
          <a href={sampleFile} download="samplebulkactivation.csv" style={{ fontSize: "14px" }}>
              Download sample import file
            </a>
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                border: "2px dashed #aaa",
                padding: "0 25%",
                height: "100px",
                margin: "10px 0",
                borderRadius: "10px",
                backgroundColor: "#eee",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input {...getInputProps()} />
              <p style={{ fontWeight: 500 }}>
                Select your files or drop it here
              </p>
            </div>
            <aside>
              <div style={{ fontWeight: 500 }}>Files</div>
              {fileData?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <span key={fileData[0].path}>
                      <FolderIcon style={{ color: "rgb(100,116,139)" }} />{" "}
                      {fileData[0].path}
                    </span>
                  </div>
                  <StyledButton
                    text={"Delete"}
                    color="#c9302c"
                    hovercolor="#d9534f"
                    onClick={() => setFileData([])}
                  />
                </div>
              )}
            </aside>
          </section>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Cancel"}
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton text={"Upload"} onClick={handleSubmit} />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default UploadDialog;

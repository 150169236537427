import React, { useState,useEffect } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import SingleProfileCreaetionById from "../ProfileCreation/SingleProfileCreaetionById";
import UploadDialogCopy from "../../Components/Activation/UploadDialogCopy"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { styled } from "@mui/material/styles";
import TableHead from "@mui/material/TableHead";
import Checkbox from "@mui/material/Checkbox";
import ProductDetailsDialog from "./ProductDetailsDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import ProfileUploadDialogCopy from "../ProfileCreation/ProfileUploadDialogCopy";
import DeleteOneDailog from "../DeleteDailogs/DeleteOneDailog";
import DeleteMultipleDialog from "../DeleteDailogs/DeleteMultipleDialog";
import StyledButton from "../StyledButton";
import CreateCard from "./CreateCard";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#64748b",
    padding: "5px 10px",
    fontWeight: 600,
    fontSize: "12px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    padding: "10px 10px",
  },
}));

const ProductDetails = ({ details, customerId, orderId,handleReload }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selected, setSelected] = React.useState([]);
  // const [reload, setReload] = useState(false);
  const rows = details?.UserSubscriptions || [];
  console.log('rowData',rows)
  // const handleReload = () => setReload(!reload);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.activationKey);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, activationKey) => {
    const selectedIndex = selected.indexOf(activationKey);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, activationKey);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleDeleteOne = async (activationKey) => {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) navigate("/sign-in");

      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/deleteSubscription`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            data: {
              activationKey: activationKey,
            },
          }
        );

        if (response.status === 200) {
          toast("Success! The data has been deleted");
          handleReload();
          window.location.reload(); // or window.location.href = window.location.href;

        } else {
          toast("Error deleting the data");
        }
      } catch (error) {
        console.error("Error deleting data:", error);
        // Handle error, display a message, etc.
      }
  };

  
  const handleDeleteMultipleFn = async (ids) => {
    const authToken = localStorage.getItem("authToken");
      if (!authToken) navigate("/sign-in");

      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/multipleDeleteSubscriptions`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
            data: {
              activationKeys: ids,
            },
          }
        );
        setSelected([])
        if (response.status === 200) {
          toast("Success! The data has been deleted");
          handleReload();
          window.location.reload(); // or window.location.href = window.location.href;
        } else {
          toast("Error deleting the data");
        }
      } catch (error) {
        console.error("Error deleting data:", error);
        // Handle error, display a message, etc.
      }
  };

  console.log(details);
  return (
    <div className="table">
      <TableContainer component={Paper}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <p>Product Details</p>
         <Stack direction="row" spacing={2}>
          {details && details.type !== 'Single Admin Purchase' && details.UserSubscriptions && details.UserSubscriptions.length !== details.quantity && (
      <CreateCard details={details} reload={handleReload} />
  )}


         <SingleProfileCreaetionById reload={handleReload} details={details} />
         <ProfileUploadDialogCopy reload={handleReload} />
    
    

              {selected.length > 0 && (
            <DeleteMultipleDialog
            selectedOrders={selected}
            handleDeleteMultipleFn={handleDeleteMultipleFn}
            types={selected.length > 1 ? 'items' : 'item'}
            />
              )}
         
          
         </Stack>
        </Stack>
        <br />
        <Table
          sx={{ minWidth: 800, borderColor: "#E0E0E0" }}
          aria-label="custom pagination table"
        >
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  style={{ color: "#04459d" }}
                  onClick={handleSelectAllClick}
                  color="primary"
                  checked={selected.length === rows.length}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
              <StyledTableCell>User ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Phone</StyledTableCell>
              <StyledTableCell>Product</StyledTableCell>
              <StyledTableCell>Action Key</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? rows?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : rows
            )?.map((row, index) => {
              const isItemSelected = selected.indexOf(row.activationKey) !== -1;
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      style={{ color: "#04459d" }}
                      onClick={(event) => handleClick(event, row.activationKey)}
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <StyledTableCell style={{ width: 80 }}>
                   {row?.userId}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 140 }}>
                    {row?.userData?.name || row?.userData?.fullName}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 80 }}>
                    {row?.userData?.email}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 140 }}>
                    {row?.userData?.phonenumber}
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 140 }}>
                    {row?.cardStyle || row?.Card?.slug}
                  </StyledTableCell>
                  <StyledTableCell>{row?.activationKey}</StyledTableCell>
                  <StyledTableCell style={{ width: 140 }}>
                    <ProductDetailsDialog
                      data={row}
                      address={details?.address}
                      customerId={customerId}
                      orderId={orderId}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {" "}
                    {/* <DeleteIcon
                      fontSize={"small"}
                      color={"error"}
                      onClick={() => handleDeleteOne(row.activationKey)}
                      sx={{
                        cursor: "pointer",
                      }}
                    /> */}
                    <DeleteOneDailog
                     orderId={row.activationKey}
                     handleDeleteOne={handleDeleteOne}
                     types={'item'}
                     />
                  </StyledTableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                colSpan={6}
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Items per page:"}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductDetails;

import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Paper,
  IconButton,
  Button,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { QRCodeSVG } from "qrcode.react";
import html2canvas from "html2canvas";

const QRDetails = () => {
  const { id } = useParams(); // Access URL parameter
  const qrRef = useRef(null);
  const [reviewData, setReviewData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const downloadButtonRef = useRef(null);
  const handleDownload = () => {
    // Hide the download button
    downloadButtonRef.current.style.display = "none";

    // Capture the screenshot of the entire qrRef component
    html2canvas(qrRef.current, { backgroundColor: null }).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = reviewData.name;
      link.click();

      // Show the download button again
      downloadButtonRef.current.style.display = "block";
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/sign-in");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/api/reviewCard/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(response.data);
      setReviewData(response.data);
    } catch (error) {
      console.error("Error fetching list:", error);
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <div>
      <Box
        sx={{
          margin: "20px 30px",
        }}
      >
        <Box sx={{ borderBottom: "1px solid #66656536" }}>
          <p>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",

                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Home
            </Link>{" "}
            /{" "}
            <Link
              to="/qrgen"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              QR Generation
            </Link>{" "}
            / <strong>QR Details</strong>
          </p>
        </Box>
        {isLoading ? (
        <Stack
          mt={2}
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "60vh",
            zIndex: 1000,
            backgroundColor: "white",
            borderRadius:3
          }}
        >
          <CircularProgress sx={{color:'#04459d'}} size={60} />
        </Stack>
      ) : (
        <Box sx={{ flexGrow: 1, marginTop: 2 }}>
          <Stack direction="row" spacing={4} alignItems="center">
            <Box
              sx={{ backgroundColor: "white", p: 2, width: 300 }}
              ref={qrRef}
            >
              <Box
                sx={{
                  border: "1px solid #aeaeae",
                  pt: 4,
                  pb: 4,
                  pl: 8,
                  pr: 8,
                  borderRadius: 4,
                  backgroundColor: "white",
                }}
              >
                <Stack direction="column" alignItems="center">
                  <br />
                  <img src={reviewData.qrCode} width={200} alt="" />
                  <Typography mt={2} sx={{ fontSize: 14 }}>
                    {reviewData.name}
                  </Typography>
                  <br />
                </Stack>
              </Box>
            </Box>

            <Stack direction="column" spacing={2}>
              <Stack>
                <Typography sx={{ fontSize: 14, color: "grey" }}>
                  User ID
                </Typography>
                <Typography sx={{ fontWeight: 600 }}>{id}</Typography>
              </Stack>
              <Stack>
                <Typography sx={{ fontSize: 14, color: "grey" }}>
                  Title
                </Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  {reviewData.name}
                </Typography>
              </Stack>
              <Stack>
                <Typography sx={{ fontSize: 14, color: "grey" }}>
                  URL
                </Typography>
                <Typography sx={{ fontWeight: 600 }}>
                  <a
                    href="https://www.google.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                    color="inherit"
                  >
                    {reviewData.url}
                  </a>
                </Typography>
              </Stack>
              <button
                ref={downloadButtonRef}
                onClick={handleDownload}
                style={{
                  cursor: "pointer",
                  padding: "8px 16px",
                  fontSize: "14px",
                  borderRadius: "8px",
                  background: "#04459d",
                  color: "#fff",
                  border: "none",
                }}
              >
                Download
              </button>
            </Stack>
          </Stack>
        </Box>
      )}
      </Box>
    </div>
  );
};

export default QRDetails;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import ActivationDialog from "../Components/Activation/ActivateDialog";
import UploadDialog from "../Components/Activation/UploadDialog";
import { Link } from "react-router-dom";
import Loader from "../Components/Loader";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#64748b",
    padding: "8px 10px",
    fontWeight: 600,
    fontSize: "12px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
    padding: "20px 10px",
  },
}));

const Activation = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const rows = useState([]);
  const [reload, setReload] = useState(false);
  const [details, setDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleReload = () => setReload(!reload);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const authToken = localStorage.getItem("authToken");
        if (!authToken) navigate("/sign-in");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-activation-orders`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        console.log(response);
        response.status === 200 && setIsLoading(false);
        setDetails(response?.data?.orders);
      } catch (error) {
        if (error?.response?.data?.error === "failed to authenticate token")
          navigate("/sign-in");
      }
    }

    fetchData();
  }, [navigate, id, reload]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          margin: "20px 30px",
        }}
      >
        <Box
          sx={{ borderBottom: "1px solid #66656536", paddingBottom: "20px" }}
        >
          <p>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Home
            </Link>{" "}
            / <strong>Activation</strong>
          </p>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap={"wrap"}
          >
            <h4 style={{ fontSize: '1.5rem' }}>Activation</h4>
            <Stack direction="row" spacing={1}>
              <ActivationDialog reload={handleReload} />
              <UploadDialog reload={handleReload} />
            </Stack>
          </Stack>
        </Box>
        <Box>
          <p>Recent Activations</p>
          <div className="table">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 800, borderColor: "#E0E0E0" }}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Order ID</StyledTableCell>
                    <StyledTableCell>Customer Name</StyledTableCell>
                    <StyledTableCell>Contact</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>Source</StyledTableCell>
                    <StyledTableCell>Product</StyledTableCell>
                    <StyledTableCell>Action</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(rowsPerPage > 0
                    ? details?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : details
                  )?.map((row, index) => {
                    return (
                      <TableRow>
                        <StyledTableCell style={{ width: 140 }}>
                          {row?.orderId}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 140 }}>
                          {row?.userData?.fullName}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 80 }}>
                          {row?.userData?.email}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 140 }}>
                          {row?.userData?.phonenumber}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 100 }}>
                          {row?.userData?.poc}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 140 }}>
                          {row?.cardStyle}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 80 }}>
                          {row?.Card?.price}
                        </StyledTableCell>
                        <StyledTableCell>{row?.activationKey}</StyledTableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 20, 30]}
                      colSpan={6}
                      count={rows?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelRowsPerPage={"Items per page:"}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Activation;

import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import sampleFile from "../../../src/assets/images/profilecreate.csv";
import { toast } from "react-toastify";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "react-toastify/dist/ReactToastify.css";
import LinearProgress from "@mui/material/LinearProgress";
import Papa from "papaparse";
import { useParams } from "react-router-dom";
import { Button, Menu, MenuItem, Stack,IconButton  } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(1),
  },
  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const ProfileUploadDialogCopy = ({ reload }) => {
  const [open, setOpen] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [cardTypes, setCardTypes] = React.useState([]);
  const { id } = useParams();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/plain": [".csv"],
      "application/vnd.ms-excel": [".csv"],
    },
    maxFiles: 1,
    onDrop: (files) => setFileData(files),
  });

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFileData([]);
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget); // Sets the button as the anchor element
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Closes the menu
  };

  useEffect(() => {
    fetchCardTypes();
  }, []);
  const fetchCardTypes = async () => {
    try {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) {
        navigate("/sign-in");
        return;
      }

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/getCardTypes`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Card Types", response.data.cards);
        setCardTypes(response.data.cards); // Assuming the API returns an array of card types
      } else {
        // Handle other status codes if needed
      }
    } catch (error) {
      console.error("Error fetching card types:", error);
    }
  };

  // const handleSubmit = async () => {
  //   let totalRows = 0;
  //   let errorOccurred = false;
  //   const authToken = localStorage.getItem("authToken");

  //   // Checks for authentication token.
  //   if (!authToken) {
  //     navigate("/sign-in");
  //     return;
  //   }

  //   // Ensures a file is selected.
  //   if (fileData.length <= 0) {
  //     toast.error("Please add the data", {
  //       autoClose: 2000
  //     });
  //     return;
  //   }

  //   setUploading(true);
  //   const file = fileData[0];
  //   const reader = new FileReader();
  //   reader.onload = async (event) => {
  //     const csvData = event.target.result;

  //     try {
  //       const parsedData = Papa.parse(csvData, {
  //         header: true,
  //       });

  //       totalRows = parsedData.data.length;
  //       let uploadedRows = 0;

  //       for (let i = 0; i < totalRows; i++) {
  //         const rowData = parsedData.data[i];
  //         const formData = new FormData();
  //         formData.append("orderId",id);
  //         formData.append("file", new Blob([Papa.unparse([rowData])], { type: 'text/csv' }));
  //         try {
  //           await axios.post(
  //             `${process.env.REACT_APP_BASE_URL}/v1/api/admin/bulk-activation`,
  //             formData,
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${authToken}`,
  //                 'Content-Type': 'multipart/form-data'
  //               },
  //             }
  //           );

  //           const progress = ((i + 1) / totalRows) * 100;
  //           setUploadProgress(progress);
  //           uploadedRows++;
  //         } catch (error) {
  //           console.error('Error during upload:', error);
  //           errorOccurred = true;
  //           if (error.response && error.response.data && error.response.data.errors) {
  //             const errorDetails = error.response.data.errors[0];
  //             const errorMessage = `Error uploading row ${i + 1}: ${errorDetails.errors.map(err => err.message).join(", ")}`;
  //             toast.error(errorMessage, {
  //               autoClose: 5000
  //             });
  //           } else {
  //             toast.error(`Error uploading row ${i + 1}: ${error.message}`, {
  //               autoClose: 5000
  //             });
  //           }
  //         }
  //       }

  //       if (!errorOccurred && uploadedRows === totalRows) {
  //         toast.success("Success! All Data uploaded successfully", {
  //           autoClose: 2000
  //         });
  //         setOpen(false);
  //         reload();
  //       }
  //     } catch (error) {
  //       console.error('Error during file reading or parsing:', error);
  //       toast.error("An error occurred during upload. Please try again.", {
  //         autoClose: 2000
  //       });
  //     } finally {
  //       setUploading(false);
  //       setUploadProgress(0);
  //     }
  //   };

  //   reader.readAsText(file);
  // };

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken");
    let errorOccurred = false;

    // Checks for authentication token.
    if (!authToken) {
      navigate("/sign-in");
      return;
    }

    // Ensures a file is selected.
    if (fileData.length <= 0) {
      toast.error("Please add the data", {
        autoClose: 2000,
      });
      return;
    }

    setUploading(true);
    const file = fileData[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const csvData = event.target.result;

      try {
        // Parse the CSV file for validation (optional)
        const parsedData = Papa.parse(csvData, {
          header: true,
        });

        const totalRows = parsedData.data.length;

        if (totalRows === 0) {
          toast.error("CSV file is empty or invalid", {
            autoClose: 2000,
          });
          setUploading(false);
          return;
        }

        // Prepare the formData with the whole CSV file
        const formData = new FormData();
        formData.append("orderId", id); // Assuming id is already available
        formData.append("file", new Blob([csvData], { type: "text/csv" }));

        try {
          // Sending the entire file in a single request
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/v1/api/admin/bulk-activation`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );
          handleClose();
          // Success notification
          toast.success("Success! All Data uploaded successfully", {
            autoClose: 2000,
          });
          setOpen(false);
          reload();
        } catch (error) {
          console.error("Error during upload:", error);
          errorOccurred = true;
          handleClose();
          // Handle error response
          if (
            error.response &&
            error.response.data &&
            error.response.data.errors
          ) {
            const errorDetails = error.response.data.errors[0];
            const errorMessage = `Error uploading: ${errorDetails.errors
              .map((err) => err.message)
              .join(", ")}`;
            toast.error(errorMessage, {
              autoClose: 5000,
            });
          } else {
            toast.error(`Error uploading: ${error.response.data}`, {
              autoClose: 5000,
            });
          }
        }
      } catch (error) {
        console.error("Error during file reading or parsing:", error);
        toast.error("An error occurred during upload. Please try again.", {
          autoClose: 2000,
        });
      } finally {
        setUploading(false);
        setUploadProgress(0);
      }
    };

    reader.readAsText(file);
  };


  const handleCopy = (cardName) => {
    navigator.clipboard.writeText(cardName).then(() => {
      toast.success(`${cardName} copied to clipboard!`); // Optional feedback
    });
  };


  return (
    <div>
      <StyledButton text={"Bulk Profile Creation"} onClick={handleClickOpen} />
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Bulk Profile Creation</DialogTitle>
        <DialogContent>
          <section className="container">
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <a
                href={sampleFile}
                download="sampleprofilecreation.csv"
                style={{ fontSize: "14px" }}
              >
                Download sample import file
              </a>
               <Stack>
      <Button onClick={handleClickMenu}>Card Styles</Button>

      {/* Menu Component */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {cardTypes.map((card) => (
          <MenuItem key={card.id} onClick={handleMenuClose}>
            {card.name}
            <IconButton
              onClick={(e) => {
                e.stopPropagation(); // Prevents Menu from closing
                handleCopy(card.name);
              }}
              size="small"
              edge="end"
              aria-label={`Copy ${card.name}`}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
            </Stack>

            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                border: "2px dashed #aaa",
                padding: "0 25%",
                height: "100px",
                margin: "10px 0",
                borderRadius: "10px",
                backgroundColor: "#eee",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input {...getInputProps()} />
              <p style={{ fontWeight: 500 }}>
                Select your files or drop it here
              </p>
            </div>
            <aside>
              <div style={{ fontWeight: 500 }}>Files</div>
              {fileData?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <span key={fileData[0].path}>
                      <FolderIcon style={{ color: "rgb(100,116,139)" }} />{" "}
                      {fileData[0].path}
                    </span>
                  </div>
                  <StyledButton
                    text={"Delete"}
                    color="#c9302c"
                    hovercolor="#d9534f"
                    onClick={() => setFileData([])}
                  />
                </div>
              )}
            </aside>
          </section>
        </DialogContent>
        {uploading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
              flexDirection: "column",
            }}
          >
            <LinearProgress
              style={{
                width: "100%",
                backgroundColor: "#d8ffd8",
                marginBottom: "10px",
              }}
              sx={{
                "& .MuiLinearProgress-barColorPrimary": {
                  backgroundColor: "#4caf50",
                },
              }}
            />
          </div>
        )}
        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Cancel"}
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton
            text={uploading ? "Uploading..." : "Upload"}
            onClick={handleSubmit}
            disabled={uploading}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProfileUploadDialogCopy;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "./Blogs.css";
import Stack from "@mui/material/Stack";
import StyledButton from "../../Components/StyledButton";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../Components/Loader";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  // border: "2px solid red",
}));

const BlogDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [details, setDetails] = useState();
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const authToken = localStorage.getItem("authToken");
        if (!authToken) navigate("/sign-in");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-single-blogs/${id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        response.status === 200 && setIsLoading(false);

        setDetails(response?.data);
      } catch (error) {
        if (error?.response?.data?.error === "failed to authenticate token")
          navigate("/sign-in");
      }
    }

    fetchData();
  }, [navigate, id, reload]);

  const getDate = (date) => {
    const inputDate = new Date(date);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return inputDate.toLocaleDateString("en-US", options);
  };

  const handleDelete = async () => {
    const result = window.confirm("Are you sure you want to delete this Blog?");
    if (result) {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) navigate("/sign-in");
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/delete-blogs/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      response.status === 200 && toast("Success! The data has been deleted");
      navigate("/blogs");
    }
  };

  const handlePublish = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/api/admin/publish/${id}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    response.status === 200 && toast("Success! The data has been updated");
    setReload(!reload);
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className="blog-container">
        <div className="blog-header">
          <p>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Home
            </Link>{" "}
            /{" "}
            <Link
              to="/blogs"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Blogs
            </Link>{" "}
            / <strong>Blog Details</strong>
          </p>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <h4 style={{ fontSize: '1.5rem' }}>Blog Details</h4>
            <Stack direction="row" spacing={1}>
              <Link to={`/edit-blog/${id}`}>
                <StyledButton text={"Edit"} variant="outlined" />
              </Link>
              <StyledButton
                text={"Delete"}
                color="#c9302c"
                hovercolor="#d9534f"
                onClick={handleDelete}
              />
              <StyledButton
                text={details?.publish ? "unPublish" : "Publish"}
                onClick={handlePublish}
              />
            </Stack>
          </Stack>
        </div>
        <Box sx={{ flexGrow: 1, marginTop: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Item>
                <div style={{ fontWeight: 700, color: "#000" }}>
                  Publish Date
                </div>
                <div>{getDate(details?.createdAt)}</div>
              </Item>
            </Grid>
            <Grid item xs={12} md={3}>
              <Item>
                <div style={{ fontWeight: 700, color: "#000" }}>Title</div>
                <div>{details?.title}</div>
              </Item>
            </Grid>
            <Grid item xs={12} md={3}>
              <Item>
                <div style={{ fontWeight: 700, color: "#000" }}>Category</div>
                <div>{details?.Category?.name}</div>
              </Item>
            </Grid>
            <Grid item xs={12} md={3}>
              <Item>
                <div style={{ fontWeight: 700, color: "#000" }}>
                  Sub-Category
                </div>
                <div>{details?.Subcategory?.name}</div>
              </Item>
            </Grid>
            <Grid item xs={12} md={3}>
              <Item>
                <div style={{ fontWeight: 700, color: "#000" }}>
                  Last Update
                </div>
                <div>{getDate(details?.updatedAt)}</div>
              </Item>
            </Grid>
            <Grid item xs={12} md={12}>
              <Item>
                <div style={{ fontWeight: 700, color: "#000" }}>Content</div>
                <div
                  className="blog-description"
                  dangerouslySetInnerHTML={{
                    __html: details?.description,
                  }}
                ></div>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default BlogDetails;

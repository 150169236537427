import React, { useState, useEffect } from "react";
import Navbar from "./NavBar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Outlet, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import "./NavBar/Navbar.css";


function Home() {
  const [isToggled, setIsToggled] = useState(false);

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsToggled(!isToggled);
  };

  useEffect(() => {
    const userAuth = localStorage.getItem("authToken") || "";
    if (!userAuth) navigate("/sign-in");

    const handleWindowResize = (e) => {
      if (window.innerWidth < 960) {
        setIsToggled(true);
      }

      if (window.innerWidth >= 960) {
        setIsToggled(false);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [navigate]);

  return (
    <div className="page-flex">
      <Navbar isToggled={isToggled} toggleSidebar={toggleSidebar} />
      <div id="page-content-wrapper">
        <div
          className={`${isToggled ? "" : "toggled-min"}`}
          onClick={toggleSidebar}
        ></div>
        <nav className="navbar navbar-expand-lg navbar-light px-2">
          <div className="d-flex align-items-center">
            <IconButton
              aria-label="delete"
              onClick={toggleSidebar}
              size="large"
              sx={{ margin: 0 }}
            >
              <MenuRoundedIcon fontSize="inherit" />
            </IconButton>
          </div>
        </nav>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Home;

import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTabs = styled(Tabs)(({ Theme }) => ({
  "&.MuiTabs-root": {
    minHeight: "0",
    marginTop: "30px",
  },
  "& .MuiTabs-flexContainer": {
    columnGap: "10px",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

const StyledTab = styled(Tab)(({ Theme }) => ({
  "&.MuiTab-root": {
    borderRadius: "30px",
    fontSize: "12px",
    padding: "10px 20px",
    fontWeight: 700,
    minHeight: "0",
    color: "rgb(100,116,139,)",
  },
  "&.Mui-selected": {
    color: "rgb(30,41,59)",
    backgroundColor: "rgba(51,65,85,0.1)",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  border: "1px solid #eee",
  borderRadius: "8px",
  marginBottom: "15px",
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CreateSocialLink = ({ option, link }) => {
  const options = {
    Whatsapp: `https://wa.me/${link}`,
    "Phone Number": `tel:${link}`,
    Email: `mailto:${link}`,
    Address: `http://maps.google.com/?q=${link}`,
  };
  return <Link to={options[option] || link}>View</Link>;
};

export default function UserSocialApps({ details }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <StyledTab label="Personal" {...a11yProps(0)} />
          <StyledTab label="Business" {...a11yProps(1)} />
          <StyledTab label="Subscription" {...a11yProps(2)} />
        </StyledTabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            {details?.ContactProfiles?.map(
              (item, index) =>
                item.profileType === 1 && (
                  <Grid item xs={6} sm={4} md={3} lg={2}>
                    <Item>
                      <Box sx={{ padding: "10px", textAlign: "left" }}>
                        <img
                          src={ `${process.env.REACT_APP_BASE_URL}/${item?.ContactOption?.image}`}
                          alt="whatsapp"
                          style={{
                            width: "54px",
                            height: "54px",
                            marginBottom: "10px",
                          }}
                        />
                        <p style={{ fontSize: "14px", marginBottom: "0" }}>
                          {item?.data?.title}
                        </p>
                      </Box>
                      <Box
                        sx={{
                          padding: "10px",
                          borderTop: "1px solid #eee",
                          fontSize: "12px",
                          textAlign: "right",
                        }}
                      >
                        <CreateSocialLink
                          option={item?.ContactOption?.name}
                          link={item?.data?.username}
                        />
                      </Box>
                    </Item>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box sx={{ width: "100%" }}>
          <Grid container>
            {details?.ContactProfiles?.map(
              (item, index) =>
                item.profileType === 2 && (
                  <Grid item xs={6} sm={4} md={3} lg={2}>
                    <Item>
                      <Box sx={{ padding: "10px", textAlign: "left" }}>
                        <img
                          src={ `${process.env.REACT_APP_BASE_URL}/${item?.ContactOption?.image}`}
                          alt="whatsapp"
                          style={{
                            width: "54px",
                            height: "54px",
                            marginBottom: "10px",
                          }}
                        />
                        <p style={{ fontSize: "14px", marginBottom: "0" }}>
                          {item?.data?.title}
                        </p>
                      </Box>
                      <Box
                        sx={{
                          padding: "10px",
                          borderTop: "1px solid #eee",
                          fontSize: "12px",
                          textAlign: "right",
                        }}
                      >
                        <CreateSocialLink
                          option={item?.ContactOption?.name}
                          link={item?.data?.username}
                        />
                      </Box>
                    </Item>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <table style={{ width: "100%" }}>
          <tr style={{ border: "1px solid #ccc" }}>
            <th style={{ padding: "8px" }}>Plan Name</th>
            <th style={{ padding: "8px" }}>Expiry Date</th>
          </tr>
          <tr style={{ border: "1px solid #ccc" }}>
            <td style={{ padding: "8px" }}>
              {details?.UserSubscription?.Card?.CardType?.name}
            </td>
            <td style={{ padding: "8px" }}>
              {details?.UserSubscription?.expirationDate}
            </td>
          </tr>
        </table>
      </CustomTabPanel>
    </Box>
  );
}

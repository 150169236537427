import * as React from "react";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Grid, Button, CircularProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputBase from "@mui/material/InputBase";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
  "& .Mui-error": {
    color: "#000",
  },
}));

const StyledTextField = ({
  name,
  label,
  type = "text",
  register,
  errors,
  defaultValue = "", // Set a default empty string
  ...props
}) => {
  const validationRules = {
    required: "This field is required",
    ...(type === "email" && {
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Invalid email address",
      },
    }),
    ...(type === "tel" && {
      pattern: {
        value: /^[0-9]{10}$/,
        message: "Phone number must be exactly 10 digits",
      },
    }),
  };

  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel
        shrink
        htmlFor={name}
        sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
      >
        {label}
      </InputLabel>
      <TextField
        autoFocus
        sx={
          errors[name] && {
            color: "red",
            borderRadius: "6px",
          }
        }
        margin="dense"
        type={type}
        fullWidth
        size="small"
        placeholder={label}
        variant="outlined"
        defaultValue={defaultValue} // Ensure defaultValue is passed correctly
        {...register(name, validationRules)}
        {...props}
      />
      {errors[name] && (
        <FormHelperText sx={{ color: "red" }}>
          {errors[name]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const StyledTextFeildWithoutValidation = ({
  name,
  label,
  type = "text",
  register,
  errors,
  ...props
}) => {
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel
        shrink
        htmlFor={name}
        sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
      >
        {label}
      </InputLabel>
      <TextField
        sx={
          errors[name] && {
            color: "red",
            borderRadius: "6px",
          }
        }
        margin="dense"
        type={type}
        fullWidth
        size="small"
        placeholder={label}
        variant="outlined"
        {...register(name)}
        {...props}
      />
      {errors[name] && (
        <FormHelperText sx={{ color: "red" }}>
          {errors[name]?.message}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  // border: "2px solid red",
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(1),
  },

  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const SingleProfileCreaetion = ({ reload, orderData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
  } = useForm({ mode: "onChange" });

  const [open, setOpen] = React.useState(false);
  const [cardTypes, setCardTypes] = React.useState([]);
  const navigate = useNavigate();
  const [lockFeilds,setLockFeilds] = React.useState(true);

  React.useEffect(() => {
    const fetchCardTypes = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
          navigate("/sign-in");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/getCardTypes`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.status === 200) {
          setCardTypes(response.data); // Assuming the API returns an array of card types
        } else {
          // Handle other status codes if needed
        }
      } catch (error) {
        console.error("Error fetching card types:", error);
      }
    };

    fetchCardTypes();
  }, [navigate]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    reset({
      fullName: "",
      phonenumber: "",
      description: "",
      company: "",
      address: "",
      pincode: "",
      gst: "",
      whatsapp: "",
      linkedin: "",
      website: "",
      profileImage: "",
      coverImage: "",
      cardStyle: "",
      quantity: "",
      price: "",
    });
    setSearchEmail("");
    setLockFeilds(true);
  };

  const onSubmit = async (data) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/api/admin/single-activation`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    setSearchEmail("");
    reset({
      fullName: "",
      phonenumber: "",
      description: "",
      company: "",
      address: "",
      pincode: "",
      gst: "",
      whatsapp: "",
      linkedin: "",
      website: "",
      profileImage: "",
      coverImage: "",
      cardStyle: "",
      quantity: "",
      price: "",
    });
    
    (response.status === 200 || response.status === 422) &&
      toast("Success! The data has been added");
    setOpen(false);
    reload();
    
    console.log(`onSubmitData`,data);
  };

  // Search Email logic
  const [searchEmail, setSearchEmail] = React.useState("");
  const [userData, setUserData] = React.useState(null);
  const [fieldsVisible, setFieldsVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");

  const handleSearch = async () => {
    try {
      if (searchEmail === "") {
        setEmailError("Please Enter Email Address");
      } else {
        setEmailError("");
        setLoading(true);
        setUserData(null);
        setFieldsVisible(false);
        setLockFeilds(false);
        reset({
          fullName: "",
          phonenumber: "",
          description: "",
          company: "",
          address: "",
          pincode: "",
          gst: "",
          whatsapp: "",
          linkedin: "",
          website: "",
          profileImage: "",
          coverImage: "",
          cardStyle: "",
          quantity: "",
          price: "",
        });

        const authToken = localStorage.getItem("authToken");
        if (!authToken) navigate("/sign-in");

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/appUser/${searchEmail}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const fetchedData = response.data.user.UserSubscription.userData;
        console.log("Data", fetchedData);
        setUserData(fetchedData);
        setFieldsVisible(true);

        reset({
          ...fetchedData,
          quantity: getValues("quantity"),
          price: getValues("price"),
          cardStyle: getValues("cardStyle"),
        });
      }
    } catch (error) {
      console.error("User not found, allow form to be filled manually.");
      setUserData(null);
      setFieldsVisible(true);
      setLockFeilds(false);
      reset({
        fullName: "",
        phonenumber: "",
        description: "",
        company: "",
        address: "",
        pincode: "",
        gst: "",
        whatsapp: "",
        linkedin: "",
        website: "",
        profileImage: "",
        coverImage: "",
        cardStyle: "",
        quantity: "",
        price: "",
      });
    } finally {
      setLoading(false);
    }
  };

  // const allFieldsDisabled = !userData && !fieldsVisible;

  return (
    <div>
      {/* Display Button */}
      <StyledButton
        variant="outlined"
        text={"Create Order"}
        onClick={handleClickOpen}
      />
      {/* Dailog Button */}
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Create new order</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Item>
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <StyledTextField
                        label={"Email"}
                        name="email"
                        type="email"
                        value={searchEmail}
                        register={register}
                        errors
                        FormHelperText={emailError}
                        onChange={(e) => setSearchEmail(e.target.value)}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Button
                        variant="contained"
                        sx={{ mt: 3, ml: 2 }}
                        onClick={handleSearch}
                      >
                        {loading ? <CircularProgress size={24} /> : "Search"}
                      </Button>
                    </Grid>
                  </Grid>
                  <strong style={{ color: "red", fontSize: "12px" }}>
                    {emailError}
                  </strong>
                </Item>
              </Grid>

              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Full name"}
                    name="fullName"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.fullName || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Phone Number"}
                    name="phonenumber"
                    type="tel"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.phonenumber || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>

              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"Description"}
                    name="description"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.description || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Company"}
                    name="company"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.company || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
              {/* Cards dropdown */}
              {!userData &&(
              <Grid item sx={12} md={6}>
                <Item>
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ minWidth: "220px", border: "none" }}
                    disabled={userData ? false : lockFeilds}
                  >
                    <InputLabel
                      shrink
                      htmlFor="add-sub-category"
                      sx={{
                        fontWeight: 700,
                        fontSize: "20px",
                        color: "#000",
                      }}
                    >
                      Card Style
                    </InputLabel>
                    <Controller
                      control={control}
                      name="cardStyle"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            labelId="select-category"
                            placeholder="Card style"
                            id="Add Category"
                            size="small"
                            name="cardStyle"
                            input={<TextField />}
                            value={value}
                            onChange={onChange}
                            sx={
                              errors.cardStyle?.type === "required" && {
                                color: "red",
                                borderRadius: "6px",
                              }
                            }
                          >
                            {cardTypes.cards.map((card) => (
                              <MenuItem key={card.id} value={card.id}>
                                {card.name}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                    {errors?.cardStyle?.type === "required" && (
                      <FormHelperText sx={{ color: "red" }}>
                        Required
                      </FormHelperText>
                    )}
                  </FormControl>
                </Item>
              </Grid>
 )}
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Quantity"}
                    name="quantity"
                    type="number"
                    register={register}
                    errors={errors}
                    disabled={userData ? false : lockFeilds}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Price"}
                    name="price"
                    type="number"
                    register={register}
                    errors={errors}
                    disabled={userData ? false : lockFeilds}
                  />
                </Item>
              </Grid>

              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Address"}
                    name="address"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.address || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Pincode"}
                    name="pincode"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.pincode || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"GST"}
                    name="gst"
                    type="text"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.gst || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"WhatsApp"}
                    name="whatsapp"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.whatsapp || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"LinkedIn"}
                    name="linkedin"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.linkedin || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"Website"}
                    name="website"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.website || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"Profile Image"}
                    name="profileImage"
                    type="text"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.profileImage || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextFeildWithoutValidation
                    label={"Cover Image"}
                    name="coverImage"
                    type="text"
                    register={register}
                    errors={errors}
                    defaultValue={userData?.coverImage || ""}
                    disabled={userData ? true : lockFeilds}
                  />
                </Item>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <StyledButton
              variant="outlined"
              text={"Cancel"}
              onClick={handleClose}
            >
              Cancel
            </StyledButton>
            <StyledButton
              type="submit"
              text={"Submit"}
              onClick={handleSubmit(onSubmit)}
              disabled={searchEmail ? false : true}
            />
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default SingleProfileCreaetion;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import StyledButton from "../StyledButton";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import axios from "axios";
import { useNavigate } from "react-router-dom/dist";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const ColorButton = styled(Button)(
  ({ theme, variant, setcolor, hovercolor }) => ({
    color: variant === "outlined" ? setcolor : "#fff",
    backgroundColor: variant === "outlined" ? "#fff" : setcolor,
    fontSize: "10px",
    padding: "4px 6px",
    border: `1px solid ${setcolor}`,
    borderRadius: "8px",
    "&:hover": {
      backgroundColor: variant === "outlined" ? "#fff" : hovercolor,
      color: variant === "outlined" ? hovercolor : "#fff",
    },
  })
);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(2),
  },

  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const StyledTabs = styled(Tabs)(({ Theme }) => ({
  "&.MuiTabs-root": {
    minHeight: "0",
    marginTop: "0px",
  },
  "& .MuiTabs-flexContainer": {
    columnGap: "10px",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

const StyledTab = styled(Tab)(({ Theme }) => ({
  "&.MuiTab-root": {
    borderRadius: "10px",
    fontSize: "12px",
    padding: "10px 0",
    fontWeight: 700,
    minHeight: "0",
    color: "rgb(100,116,139,)",
    minWidth: 0,
  },
  "&.Mui-selected": {
    color: "rgb(30,41,59)",
    // backgroundColor: "rgba(51,65,85,0.1)",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  "&.MuiPaper-root": {
    padding: "10px 10px 0 0",
  },
  // border: "1px solid #eee",
  borderRadius: "8px",
  marginBottom: "10px",
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Title = styled(Box)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  marginBottom: "5px",
}));

const Content = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  marginBottom: "15px",
  fontWeight: "500",
}));

const ProductDetailsDialog = ({ data, address, customerId, orderId }) => {
  console.log();
  const AWS_S3_BUCKET_URL = process.env.REACT_APP_AWS_S3_BUCKET_URL;

  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const downloadAsImage = async (link, name) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-s3-signed-url`,
      { url: link },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    const imageData = `data:${response?.data?.mime};base64,${response?.data?.data}`;
    download(imageData, name);
  };

  const download = (imgData, name) => {
    const a = document.createElement("a");
    a.href = imgData;
    a.download = `${data?.userData?.fullName || data?.userData?.name}_${
      data?.id
    }_${name}.png`;
    a.click();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        sx={{ fontSize: "12px" }}
        variant="text"
        onClick={handleClickOpen}
      >
        View
      </Button>
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle sx={{ color: "#04459d" }}>
          {data?.userData?.fullName || data?.userData?.name} - Details
        </DialogTitle>
        <DialogContent>
          <Box>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <StyledTab label="Info" {...a11yProps(0)} />
              <StyledTab label="Card front" {...a11yProps(1)} />
              <StyledTab label="Card back" {...a11yProps(2)} />
            </StyledTabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box sx={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={12} sm={10}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <Item>
                        <Title>Order ID</Title>
                        <Content>{orderId}</Content>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Item>
                        <Title>Name</Title>
                        <Content>
                          {data?.userData?.fullName || data?.userData?.name}
                        </Content>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Item>
                        <Title>Product</Title>
                        <Content>{data?.Card?.name}</Content>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Item>
                        <Title>Customer ID</Title>
                        <Content>{customerId}</Content>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Item>
                        <Title>Address</Title>
                        <Content>{data?.userData?.address}</Content>
                      </Item>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Item>
                        <Title>Print Type</Title>
                        <Content>{data?.printOption}</Content>
                      </Item>
                    </Grid>
                    {data?.Card?.slug?.split("-")[0] === "custom" && (
                      <Grid item xs={12} sm={3}>
                        <Item>
                          <Title>Design Assistance</Title>
                          <Content>
                            {data?.customizationAssistance ? "Yes" : "No"}
                          </Content>
                        </Item>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <Item>
                    <Title>QR</Title>
                    <Content>
                      <div
                        style={{
                          borderRadius: "50%",
                          // border: "1px solid black",
                        }}
                      >
                        <img
                          src={data?.qrCode}
                          alt=""
                          style={{
                            width: "100%",
                            height: "100%",
                            // padding: "14px",
                          }}
                        />
                      </div>
                      <Button
                        variant="text"
                        sx={{ fontSize: "12px" }}
                        onClick={() => download(data?.qrCode, "QR")}
                      >
                        Download
                      </Button>
                    </Content>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box sx={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Item>
                    <Title>Design</Title>
                    <Content>
                      <img
                        src={`https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${data?.frontImage}`}
                        alt={"Back"}
                        style={{
                          width: "90%",
                          height: "90%",
                          // border: "1px solid black",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                      />
                    </Content>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Item>
                    <Title>Design Element</Title>
                    <Content>
                      <Grid container style={{ alignItems: "flex-end" }}>
                        {data?.frontImageComponents?.map((image, index) => (
                          <Grid item xs={2}>
                            <img
                              src={`https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${image}`}
                              alt={"Back"}
                              style={{
                                width: "90%",
                                height: "90%",
                                // border: "1px solid black",
                                boxShadow:
                                  "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                borderRadius: "5px",
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Content>
                  </Item>
                </Grid>
              </Grid>
              {data?.frontTextAndStyle?.map((textData, index) => (
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Item>
                      <Title>
                        {" "}
                        {(index === 0 && "Primary Text Field") ||
                          (index === 1 && "Secondary Text Field") ||
                          "Additional Text Field"}
                      </Title>
                      <Content>{textData?.text}</Content>
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Grid container style={{ alignItems: "flex-end" }}>
                      <Grid item xs={12} sm={6}>
                        <Item>
                          <Title>Font Family</Title>
                          <Content>{textData?.font}</Content>
                        </Item>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Item>
                          <Title>Font Size</Title>
                          <Content>{textData?.fontSize}</Content>
                        </Item>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Item>
                          <Title>Text Color</Title>
                          <Content>{textData?.color}</Content>
                        </Item>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Item>
                          <Title>Font Weight</Title>
                          <Content>{textData?.fontWeight}</Content>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Box sx={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={12} sm={3}>
                  <Item>
                    <Title>Design</Title>
                    <Content>
                      <img
                        src={`https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${data?.backImage}`}
                        alt={"Back"}
                        style={{
                          width: "90%",
                          height: "90%",
                          // border: "1px solid black",
                        }}
                      />
                    </Content>
                  </Item>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Item>
                    <Title>Design Element</Title>
                    <Content>
                      <Grid container style={{ alignItems: "flex-end" }}>
                        {data?.backImageComponents?.map((image, index) => (
                          <Grid item xs={2}>
                            <img
                              src={`https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${image}`}
                              alt={"Back"}
                              style={{
                                width: "90%",
                                height: "90%",
                                // border: "1px solid black",
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Content>
                  </Item>
                </Grid>
              </Grid>
              {data?.backTextAndStyle?.map((textData, index) => (
                <Grid container>
                  <Grid item xs={12} sm={3}>
                    <Item>
                      <Title>
                        {" "}
                        {(index === 0 && "Primary Text Field") ||
                          (index === 1 && "Secondary Text Field") ||
                          "Additional Text Field"}
                      </Title>
                      <Content>{textData?.text}</Content>
                    </Item>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Grid container style={{ alignItems: "flex-end" }}>
                      <Grid item xs={12} sm={6}>
                        <Item>
                          <Title>Font Family</Title>
                          <Content>{textData?.font}</Content>
                        </Item>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Item>
                          <Title>Font Size</Title>
                          <Content>{textData?.fontSize}</Content>
                        </Item>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Item>
                          <Title>Text Color</Title>
                          <Content>{textData?.color}</Content>
                        </Item>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Item>
                          <Title>Font Weight</Title>
                          <Content>{textData?.fontWeight}</Content>
                        </Item>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </CustomTabPanel>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Close"}
            onClick={handleClose}
          />
          <StyledButton text={"Save"} onClick={handleClose} />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProductDetailsDialog;

import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

const StackedBarChart = ({ userData }) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          stepSize: 20,
        },
        stacked: true,
      },
    },
    plugins: {
      tooltip:{
        backgroundColor:"whitesmoke",
        bodyColor:"#000",
        titleColor:"#000",
        padding:"10",
        titleFont:"bold",
        titleAlign:"center",
        bodyAlign:"center"
      },
      datalabels: {
        display: true,
        color: "#fff",
      },
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
  };

  const getMonth = (date) => {
    const inputDate = new Date(date);
    const options = { month: "short" };
    return inputDate.toLocaleDateString("en-US", options);
  };

  const proLabels = userData?.totalProSubscriptions?.map((data) =>
    getMonth(data?.date_trunc)
  );

  const standardLabels = userData?.totalStandardSubscriptions?.map((data) =>
    getMonth(data?.date_trunc)
  );

  const proSubCount = userData?.totalProSubscriptions?.map(
    (data) => data.count
  );

  const standardSubCount = userData?.totalStandardSubscriptions?.map(
    (data) => data.count
  );

  const data = {
    labels:
      proLabels?.length > standardLabels?.length ? proLabels : standardLabels,
    datasets: [
      {
        label: "Standard",
        data: standardSubCount,
        backgroundColor: "rgb(4, 69, 157)",
        hoverBackgroundColor:"#72A0C1"
      },
      {
        label: "Pro",
        data: proSubCount,
        backgroundColor: "rgb(249, 213, 114)",
        hoverBackgroundColor:"#72A0C1"
      },
    ],
  };

  return <Bar width={300} height={300} options={options} data={data} />;
};

export default StackedBarChart;

import * as React from "react";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputBase from "@mui/material/InputBase";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
  "& .Mui-error": {
    color: "#000",
  },
}));

const StyledTextField = ({
  name,
  label,
  // value,
  error,
  type = "text",
  register,
  errors,
}) => {
  return (
    <FormControl variant="standard" fullWidth>
      <InputLabel
        shrink
        htmlFor="name"
        sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
      >
        {label}
      </InputLabel>
      <TextField
        autoFocus
        sx={
          errors[name] && {
            color: "red",
            borderRadius: "6px",
          }
        }
        margin="dense"
        type={type}
        fullWidth
        size="small"
        placeholder={label}
        variant="outlined"
        helperText={error === name && "Required"}
        {...(type !== "email"
          ? { ...register(name, { required: true }) }
          : {
              ...register(name, {
                required: true,
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                },
              }),
            })}
        // {...register(name, { required: true })}
      />
      {errors[name]?.type === "required" && (
        <FormHelperText sx={{ color: "red" }}>Required</FormHelperText>
      )}
      {errors[name]?.type === "pattern" && (
        <FormHelperText sx={{ color: "red" }}>Invalid Email</FormHelperText>
      )}
    </FormControl>
  );
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  // border: "2px solid red",
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(1),
  },

  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const ActivationDialog = ({ reload }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({ mode: "onChange" });

  const [open, setOpen] = React.useState(false);
  const [cardTypes, setCardTypes] = React.useState([]);
  const navigate = useNavigate();
  React.useEffect(() => {
    const fetchCardTypes = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) {
          navigate("/sign-in");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/getCardTypes`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        if (response.status === 200) {
          setCardTypes(response.data); // Assuming the API returns an array of card types
        } else {
          // Handle other status codes if needed
        }
      } catch (error) {
        console.error("Error fetching card types:", error);
      }
    };

    fetchCardTypes();
  }, [navigate]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/v1/api/admin/single-activation`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    (response.status === 200 || response.status === 422) &&
      toast("Success! The data has been added");
    setOpen(false);
    reload();
  };

  return (
    <div>
      <StyledButton
        variant="outlined"
        text={"Activate"}
        onClick={handleClickOpen}
      />
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Activate</DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Name"}
                    name="fullName"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Email"}
                    name="email"
                    type="email"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Address"}
                    name="address"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Pincode"}
                    name="pincode"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                <FormControl
                fullWidth
                variant="standard"
                sx={{ minWidth: "220px", border: "none" }}
              >
                <InputLabel
                  shrink
                  htmlFor="add-sub-category"
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    color: "#000",
                  }}
                >
                  Card Style
                </InputLabel>
                <Controller
                  control={control}
                  name="cardStyle"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                      labelId="select-category"
                      placeholder="Card style"
                      id="Add Category"
                      size="small"
                      name="cardStyle"
                      input={<TextField />}
                      value={value}
                      onChange={onChange}
                      sx={
                        errors.cardStyle?.type === "required" && {
                          color: "red",
                          borderRadius: "6px",
                        }
                      }
                    >
  {cardTypes.cards.map((card) => (
  <MenuItem key={card.id} value={card.name}>
    {card.name}
  </MenuItem>
))}
                      </Select>
                    );
                  }}
                />
                {errors?.cardStyle?.type === "required" && (
                  <FormHelperText sx={{ color: "red" }}>Required</FormHelperText>
                )}
              </FormControl>
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Price"}
                    name="price"
                    type="number"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"POC"}
                    name="poc"
                    type="email"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Contact"}
                    name="phonenumber"
                    type="number"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
              <Grid item sx={12} md={6}>
                <Item>
                  <StyledTextField
                    label={"Company"}
                    name="company"
                    register={register}
                    errors={errors}
                  />
                </Item>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <StyledButton
              variant="outlined"
              text={"Cancel"}
              onClick={handleClose}
            >
              Cancel
            </StyledButton>
            <StyledButton
              type="submit"
              text={"Submit"}
              onClick={handleSubmit(onSubmit)}
            />
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default ActivationDialog;

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputBase from "@mui/material/InputBase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(2),
  },

  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
}));

const SubCategoryDialog = ({ reload }) => {
  const [open, setOpen] = React.useState(false);
  const [catValue, setCatValue] = React.useState("");
  const [subCatValue, setSubCatValue] = React.useState("");
  const [showSubCatError, setShowSubCatError] = React.useState(false);
  const [showCatError, setShowCatError] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    async function fetchData() {
      const authToken = localStorage.getItem("authToken");
      if (!authToken) navigate("/sign-in");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-all-category`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setCategories(response?.data);
    }

    fetchData();
  }, [navigate]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShowCatError(false);
    setShowSubCatError(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    if (catValue === "") {
      setShowCatError(true);
    }
    if (subCatValue === "") {
      setShowSubCatError(true);
    } else if (subCatValue !== "" && catValue !== "") {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/subcategory`,
        {
          name: subCatValue,
          categoryId: catValue,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      response.status === 200 && toast("Success! The data has been added");
      reload();
      setOpen(false);
    }
  };

  return (
    <div>
      <StyledButton text={"Add"} onClick={handleClickOpen} />
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Add Sub Category</DialogTitle>
        <DialogContent>
          <FormControl
            variant="standard"
            fullWidth
            error={showCatError ? true : false}
          >
            <InputLabel
              shrink
              htmlFor="select-category"
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#000",
              }}
            >
              Select Category
            </InputLabel>
            <Select
              labelId="select-category"
              id="Add Category"
              value={catValue}
              label="Add Category"
              size="small"
              onChange={(e) => setCatValue(parseInt(e.target.value))}
              error={showCatError ? true : false}
              helperText={showCatError && "Category is required"}
              input={<TextField />}
            >
              {categories?.map((category, index) => (
                <MenuItem key={index} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {showCatError && (
              <FormHelperText>Category is required</FormHelperText>
            )}
          </FormControl>

          <FormControl variant="standard" fullWidth sx={{ marginTop: "15px" }}>
            <InputLabel
              shrink
              htmlFor="sub-category"
              sx={{
                fontWeight: 700,
                fontSize: "20px",
                color: "#000",
              }}
            >
              Title
            </InputLabel>
            <TextField
              value={subCatValue}
              error={showSubCatError ? true : false}
              margin="dense"
              id="sub-category"
              label="Enter Sub Category"
              type="text"
              fullWidth
              size="small"
              variant="outlined"
              helperText={showSubCatError && "Sub Category Name is required"}
              onChange={(e) => setSubCatValue(e.target.value)}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Cancel"}
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton text={"Submit"} onClick={handleSubmit}>
            Subscribe
          </StyledButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SubCategoryDialog;

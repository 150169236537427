import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./Modal.css";

const Modal = ({ isOpen, onClose, onSubmit }) => {
  const [couponCode, setCouponCode] = useState("");
  const [type, setType] = useState("Percent");
  const [value, setValue] = useState("");
  const [usage, setUsage] = useState("");
  const [status, setStatus] = useState("Active");

  const handleCouponCodeChange = (e) => {
    setCouponCode(e.target.value);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
  };

  const handleValueChange = (e) => {
    setValue(e.target.value);
  };

  const handleUsageChange = (e) => {
    setUsage(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = () => {
    const couponData = {
      couponCode,
      type,
      value,
      usage,
      status,
    };
    onSubmit(couponData);
    onClose();
  };

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (isOpen && e.key === "Escape") {
        onClose();
      }
    };

    window.addEventListener("keydown", handleEscapeKey);

    return () => {
      window.removeEventListener("keydown", handleEscapeKey);
    };
  }, [isOpen, onClose]);

  const handleClickOutside = (e) => {
    if (isOpen && !e.target.closest(".modal-content")) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <h2>Add Coupon</h2>
        <form>
          <div className="form-group">
            <label>Coupon Code</label>
            <input className="ip"
              type="text"
              value={couponCode}
              onChange={handleCouponCodeChange}
              placeholder="Enter code"
            />
          </div>
          <div className="form-group">
            <label>Type</label>
            <select value={type} onChange={handleTypeChange}>
              <option value="Percent">Percent</option>
              <option value="Fixed">Fixed</option>
            </select>
          </div>
          <div className="form-group">
            <label>Value</label>
            <input className="ip"
              type="text"
              value={value}
              onChange={handleValueChange}
              placeholder="Enter value"
            />
          </div>
          <div className="form-group">
            <label>Usage</label>
            <input className="ip"
              type="text"
              value={usage}
              onChange={handleUsageChange}
              placeholder="Enter usage"
            />
          </div>
          <div className="form-group">
            <label>Status</label>
            <select value={status} onChange={handleStatusChange}>
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </select>
          </div>
          <div className="modal-buttons">
            <button type="button" className="Cancel-btn" onClick={onClose}>
              Cancel
            </button>
            <button type="button" className="Submit-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Modal;
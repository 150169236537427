// QRCreation.js
import React, { useState, useRef } from "react";
import StyledButton from "../../Components/StyledButton";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from 'react-router-dom';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Stack, Typography } from "@mui/material";
import QRCode from "qrcode";
import html2canvas from "html2canvas"; 
import axios from 'axios';

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
  "& .Mui-error": {
    color: "#000",
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "20px",
    padding: theme.spacing(2),
  },
}));

const QRCreation = ({ onCreate }) => {
  const [qrTitle, setQrTitle] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  const [qrCodeBase64, setQrCodeBase64] = useState(null);
  const [visibleQr, setVisibleQr] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const qrRef = useRef(null); 

  const handleGenerate = async () => {
    try {
      const base64Image = await QRCode.toDataURL(qrUrl);
      setQrCodeBase64(base64Image);
      setVisibleQr(true);
      const authToken = localStorage.getItem("authToken");
      if (!authToken) navigate("/sign-in");
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/reviewCard/create`,
        { 
            name: qrTitle,
            url: qrUrl,
            qrCode: base64Image
         },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (onCreate) onCreate(); 
      toast.success("Success! The data has been added");
    } catch (error) {
      console.error("Failed to generate QR code", error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setQrTitle("");
    setQrUrl("");
    setVisibleQr(false);
    setQrCodeBase64(null);
  };

  const handleReset = () => {
    setVisibleQr(false);
    setQrTitle("");
    setQrUrl("");
    setQrCodeBase64(null);
  };

  const handleDownload = () => {
    if (qrRef.current) {
      html2canvas(qrRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = `${qrTitle}.png`;
        link.click();
      });
    }
  };

  return (
    <div>
      <StyledButton text={"Generate New"} onClick={handleClickOpen} />

      <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle>{"Generate New QR Code"}</DialogTitle>
        <DialogContent>
          <Stack direction="column">
            {!visibleQr && (
              <Stack direction="column" spacing={2}>
                <TextField
                  placeholder="Enter Name"
                  value={qrTitle}
                  onChange={(e) => setQrTitle(e.target.value)}
                />
                <TextField
                  placeholder="Enter URL"
                  value={qrUrl}
                  onChange={(e) => setQrUrl(e.target.value)}
                />
                <StyledButton text="Create" onClick={handleGenerate} />
              </Stack>
            )}

            {visibleQr && qrCodeBase64 && (
              <Box sx={{ backgroundColor: "white", p: 2 }} ref={qrRef}>
                <Box
                  sx={{
                    border: "1px solid #aeaeae",
                    pt: 4,
                    pb: 4,
                    pl: 8,
                    pr: 8,
                    borderRadius: 4,
                    backgroundColor: "white",
                  }}
                >
                  <Stack direction="column" alignItems="center">
                    <img src={qrCodeBase64} alt="QR Code" width={200} />
                    <Typography mt={2} sx={{ fontSize: 14 }}>
                      {qrTitle}
                    </Typography>
                    <br />
                  </Stack>
                </Box>
              </Box>
            )}
          {visibleQr && (
            <StyledButton text="Download" onClick={handleDownload} />
          )}
          </Stack>
      
        </DialogContent>

        <DialogActions>
        {visibleQr && (
          <Button onClick={handleReset}>Create New</Button>
        )}
          <StyledButton
            variant="outlined"
            text={"Close"}
            onClick={handleClose}
          />
        </DialogActions>
      </StyledDialog>
    </div>
  );
};

export default QRCreation;

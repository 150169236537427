import React, { useState } from "react";
import axios from "axios";
import {  useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Signup = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [termsPolicy, setTermsPolicy] = useState(false);
  const [error, setError] = useState("");

  // const emailError = useState("");
  // const passwordError = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [fullNameError, setFullNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const validateFullName = () => {
    if (fullName.trim() === "") {
      setFullNameError("Full name is required.");
    } else {
      setFullNameError("");
    }
  };
  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!email.match(emailRegex)) {
      setEmailError("Invalid email address.");
    } else {
      setEmailError("");
    }
  };
  // const validatePassword = () => {
  //   if (password.length < 6) {
  //     setPasswordError("Password must be at least 6 characters.");
  //   } else {
  //     setPasswordError("");
  //   }
  // };
  const validatePassword = () => {
    if (password.trim() === "") {
      setPasswordError("Password is required.");
    } else {
      setPasswordError("");
    }
  };

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  //   const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    validateFullName();
    validateEmail();
    validatePassword();

    if (fullNameError || emailError || passwordError) {
      return;
    }

    // Add validation for terms and policy checkbox
    if (!termsPolicy) {
      setError("Please accept the terms and policy.");
      return;
    }

    // Make the API request for signup
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/api/auth/sign-up`,
        {
          fullName,
          email,
          password,
          company,
        }
      );

      if (response.status === 200) {
        navigate("/dashboard");
        toast.success("Account created successfully.");
      } else {
        // Handle other response statuses or errors from the API
        console.log("API request failed with status: " + response.status);
        if (response.status === 409) {
          toast.error("Email is already registered.");
        } else {
          toast.error("An error occurred while signing up.");
        }
      }
    } catch (error) {
      console.error("API request error", error);
      setError("An error occurred while signing up.");
    }
  };

  const verticalLineStyle = {
    borderLeft: "2px solid lightgray",
    height: "80%",
    position: "absolute",
    left: "-10px",
  };

  return (
    <div className="wrapper">
      <ToastContainer />
      <nav className="navbar navbar-expand-lg fixed">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              className="header-logo img-fluid"
              src={require("../../assets/images/c.png")}
              alt="Logo"
              style={{ maxWidth: "170px" }}
            />
          </a>
          <button
            className={`navbar-toggler ${isMenuOpen ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/faq"
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    fontWeight: "normal", // Add this line
                  }}
                >
                  {!isMenuOpen && <span style={verticalLineStyle}></span>}
                  Need any help?
                </a>
              </li>
              {/* Add more menu items here */}
            </ul>
          </div>
        </div>
      </nav>

      <div className="container border border">
        <section className="login">
          <div className="row align-items-center">
            <div
              className="col-lg-6  d-lg-block  login-left d-none d-sm-block"
              style={{ marginTop: "110px" }}
            >
              <img
                src={require("../../assets/images/login-group.png")}
                className="img-fluid d-none d-lg-block d-block mx-auto login-image"
                alt="Login"
                // style={{ height: "500px" }}
                style={{ maxWidth: "600px", width: "500px" }}
              />
            </div>

            <div className="col-lg-6 login-right">
              <div className="login-account">
                <div>
                  <img
                    src={require("../../assets/images/logo-icon.png")}
                    className="footer-logo d-block mx-auto mt-5"
                    alt="Logo Icon"
                    style={{
                      maxHeight: "100%",
                      width: "50px",
                      marginBottom: "30px",
                    }}
                  />
                  <div className="text-center">
                    <h2 style={{ color: "#04459D", fontWeight: "bolder",fontSize:'2rem' }}>
                      Sign up
                    </h2>
                    <p className="d-flex justify-content-center">
                      Already have an account?
                      <p
                        className="d-flex signup"
                        style={{ marginLeft: "10px" }}
                      >
                        <a className=" fw-bold" href="/sign-in" style={{textDecoration:'none'}}>
                          Sign in
                        </a>
                      </p>
                    </p>
                  </div>
                  <div className="text-center"></div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row justify-content-center">
                    {/* <div className="col align-self-center"> */}

                    <div className="row justify-content-center">
                      <div className="col-8 ">
                        <label for="exampleInputPassword1">Full Name</label>
                        <input
                          id="fullname"
                          type="text"
                          className="form-control in2"
                          placeholder="Full Name"
                          // aria-label="Username"
                          // aria-describedby="basic-addon1"
                          style={{
                            fontSize: "14px",
                            height: "45px",
                            marginBottom: "10px",
                          }}
                          value={fullName}
                          onChange={(e) => {
                            setFullName(e.target.value);
                            validateFullName();
                          }}
                        />
                        {fullNameError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "4px",
                            }}
                          >
                            {fullNameError}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-8 form-group">
                        {/* {error && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginBottom: "4px",
                              textAlign: "center",
                            }}
                          >
                            {error}
                          </p>
                        )} */}
                        <label for="exampleInputEmail1">Email address</label>
                        <input
                          id="email"
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ fontSize: "14px", height: "45px" }}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            validateEmail();
                          }}
                        />
                        {emailError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "4px",
                            }}
                          >
                            {emailError}
                          </p>
                        )}
                      </div>
                      {/* </div> */}
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-8 form-group">
                        <label for="exampleInputPassword1">Password</label>
                        <input
                          id="password"
                          type="password"
                          className="form-control in2"
                          placeholder="Password"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ fontSize: "14px", height: "45px" }}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            validatePassword();
                          }}
                        />
                        {passwordError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "4px",
                            }}
                          >
                            {passwordError}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-8 form-group">
                        <label for="details">Company</label>
                        <input
                          id="fullname"
                          type="text"
                          className="form-control in2"
                          placeholder="Company Name"
                          // aria-label="Username"
                          // aria-describedby="basic-addon1"
                          style={{
                            fontSize: "14px",
                            height: "45px",
                            marginBottom: "10px",
                          }}
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-8 d-flex justify-content-between">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="termsnadcond"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="termsPolicy"
                          >
                            I agree to the Terms of service and Privacy Policy
                          </label>
                        </div>
                      </div>
                    </div>

                    <div
                      className="row justify-content-center"
                      style={{
                        marginBottom: "60px",
                        marginTop: "20px",
                        // paddingLeft: "30px",
                      }}
                    >
                      <div className="col-8">
                        <button
                          id="login-button"
                          className="col-12 btn btn-primary"
                          type="submit"
                          style={{ padding: "5px" }}
                        >
                          create your free account
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="footer" style={{ marginTop: "30px" }}>
        <div className="container">
          <div className="">
            {/* Logo */}
            <img
              src={require("../../assets/images/c.png")}
              alt="Connct Logo"
              style={{ maxWidth: "200px" }}
            />
            {/* Tagline */}
            <div style={{ margin: "10px 0", fontSize: "20px" }}>
              dont just say hello, just 👋 Connct
            </div>
            {/* Horizontal Line */}
            <hr style={{ backgroundColor: "lightgray", height: "2px" }} />
            {/* Copyright Notice */}
            <div style={{ margin: "10px 0" }}>
              Copyright © 2022. All rights reserved Connct
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;

import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const PieChart = ({ userData }) => {
  ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      tooltip:{
        backgroundColor:"whitesmoke",
        bodyColor:"#000",
        titleColor:"#000",
        padding:"10",
        titleFont:"bold",
        titleAlign:"center",
        bodyAlign:"center"
      },
      datalabels: {
        display: true,
        color: "#fff",
        anchor: "end",
        align: "start",
        font: {
          weight: 700,
          size: 10,
        },
        textShadowColor: "#000",
        textShadowBlur: 5,
        formatter: (value, context) => {
          const totalValue = context?.dataset?.data?.reduce(
            (acc, curr) => acc + curr,
            0
          );
          const valuePercent = ((value / totalValue) * 100).toFixed(1);
          return `${valuePercent}%`;
        },
      },
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
  };

  const labels = ["Website Orders", "Bulk Purchase", "Single Admin Purchase"];
  const orderData = [
    userData?.orderCount,
    userData?.bulkOrderCount,
    userData?.singleOrderCount,
  ];

  const data = {
    labels,
    datasets: [
      {
        // label: " # of orders",
        data: orderData,
        backgroundColor: [
          "rgb(98, 0, 238, 0.65)",
          "rgb(188, 159, 230, 0.65)",
          "rgb(2, 211, 201, 0.65)",
        ],
        borderColor: ["#fff", "#fff", "#fff"],
        borderWidth: 3,
      },
    ],
  };

  return (
    <div className="pie-chart">
      <Pie width={250} height={250} options={options} data={data} />
    </div>
  );
};

export default PieChart;

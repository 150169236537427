import React, { useEffect, useState } from "react";
import StyledButton from "../../Components/StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom'; 

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  // border: "2px solid red",
}));

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
}));

const UpdateCategory = () => {
  const { id } = useParams();
  const [catValue, setCatValue] = useState("");
  const [showError, setShowError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) navigate("/sign-in");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-single-category/${id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setCatValue(response?.data?.name);
      } catch (error) {
        if (error?.response?.data?.error === "failed to authenticate token")
          navigate("/sign-in");
      }
    }

    fetchData();
  }, [navigate, id]);

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    if (catValue === "") {
      setShowError(true);
    } else {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/update-category/${id}`,
        { name: catValue },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      response?.status === 200 && toast("Success! The data has been updated");
      navigate("/category");
    }
  };

  return (
    <div className="blog-container">
      <div className="blog-header">
        <p>
        <Link
    to="/dashboard"
    style={{
      textDecoration: 'none',
      color: '#454545',
      borderBottom: '1px solid transparent', // Initial border
      transition: 'border-bottom 0.3s ease', // Add smooth transition
    }}
    onMouseOver={(e) => (e.target.style.borderBottom = '1px solid #454545')} // Show underline on hover
    onMouseOut={(e) => (e.target.style.borderBottom = '1px solid transparent')} // Hide underline on mouseout
  >
    Home
  </Link>{" "} / <strong>Edit Category</strong>
        </p>
        <h4 style={{ fontSize: '1.5rem' }}>Edit Category</h4>
      </div>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <Grid item xs={12} sm={5}>
            <Item>
              {" "}
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  htmlFor="category"
                  sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
                >
                  Enter Category
                </InputLabel>
                <TextField
                  autoFocus
                  value={catValue}
                  required
                  error={showError ? true : false}
                  margin="dense"
                  id="category"
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  helperText={showError && "Category Name is required"}
                  onChange={(e) => setCatValue(e.target.value)}
                />
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={5} sx={{ marginTop: "20px" }}>
            <StyledButton text={"Update"} onClick={handleSubmit} />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default UpdateCategory;

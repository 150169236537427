import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputBase from "@mui/material/InputBase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(1),
  },

  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const CategoryDialog = ({ reload }) => {
  const [open, setOpen] = React.useState(false);
  const [catValue, setCatValue] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setShowError(false);
    setOpen(false);
  };

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    if (catValue === "") {
      setShowError(true);
    } else {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/category`,
        { name: catValue },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      response.status === 200 && toast("Success! The data has been added");
      setOpen(false);
      reload();
    }
  };

  return (
    <div>
      <StyledButton text={"Add"} onClick={handleClickOpen} />
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <FormControl variant="standard" fullWidth>
            <InputLabel
              shrink
              htmlFor="category"
              sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
            >
              Enter Category
            </InputLabel>
            <TextField
              autoFocus
              value={catValue}
              required
              error={showError ? true : false}
              margin="dense"
              id="category"
              label="Enter Category"
              type="text"
              fullWidth
              size="small"
              variant="outlined"
              helperText={showError && "Category Name is required"}
              onChange={(e) => setCatValue(e.target.value)}
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Cancel"}
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton text={"Submit"} onClick={handleSubmit}>
            Subscribe
          </StyledButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default CategoryDialog;

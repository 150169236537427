import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCreditCard,faGem,faUserGroup,} from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import { useEffect, useRef } from "react";
import axios from "axios";
import { useState } from "react";
import "./Dashboard.css";
import BarChart from "../../Components/DashboardChart/BarChart";
import PieChart from "../../Components/DashboardChart/PieChart";
import StackedBarChart from "../../Components/DashboardChart/StackedBarChart";
import MultiBarChart from "../../Components/DashboardChart/MultiBarChart";
import { useNavigate } from "react-router-dom";
import DownloadChartHam from "../../Components/DashboardChart/DownloadChartHam";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";

const Dashboard = () => {
  const barChartRef = useRef();
  const stackedChartRef = useRef();
  const multiBarChartRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [userData, setUserData] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) navigate("/sign-in");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-all-users-count`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.status === 200) {
          setUserData(response?.data?.counts);
          setIsLoading(false);
        }
      } catch (error) {
        if (error?.response?.data?.error === "failed to authenticate token")
          navigate("/sign-in");
      }
    }

    fetchData();
  }, [navigate]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <Container fluid className="mt-4 px-4">
        <div>
          <p>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Home
            </Link>{" "}
            / <strong>Dashboard</strong>
          </p>
          <h2 className="border-bottom pb-4" style={{ fontSize: '1.5rem' }}>
  Dashboard
</h2>

        </div>
        <Row>
          <Col md={8}>
            <Row>
              <Col md={4}>
                <div className="user-card text-center">
                  <FontAwesomeIcon
                    icon={faUserGroup}
                    style={{ color: "blue" }}
                  />
                  <div className="user-count">{userData?.Standard}</div>
                  <p>Total Standard Users</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="user-card text-center">
                  <FontAwesomeIcon
                    icon={faGem}
                    style={{ color: "gold" }}
                    size="lg"
                  />
                  <div className="user-count">{userData?.Pro}</div>
                  <p>Total Pro Users</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="user-card text-center">
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    style={{ color: "#0aace2" }}
                  />
                  <div className="user-count">{userData?.totalUsers}</div>
                  <p>Total Users</p>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="chart-card">
                <div className="chart-header">
                  <h4>New Users</h4>
                  <DownloadChartHam
                    chartElement={barChartRef}
                    chartData={[userData?.totalUsersByMonth]}
                    csvHeaders={["Category", "User signup"]}
                  />
                </div>
                <div ref={barChartRef} style={{ backgroundColor: "#fff" }}>
                  <BarChart userData={userData} />
                </div>
              </div>
              <div className="chart-card">
                <div className="chart-header">
                  <h4>Order Type Ratio</h4>
                </div>
                <div>
                  <PieChart userData={userData} />
                </div>
              </div>
            </Row>
            <Row>
              <div className="chart-card">
                <div className="chart-header">
                  <h4>Subscriptions</h4>
                  <DownloadChartHam
                    chartElement={stackedChartRef}
                    chartData={[
                      userData?.totalStandardSubscriptions,
                      userData?.totalProSubscriptions,
                    ]}
                    csvHeaders={["Category", "Standard", "Pro"]}
                  />
                </div>
                <div ref={stackedChartRef}>
                  <StackedBarChart userData={userData} />
                </div>
              </div>
              <div className="chart-card">
                <div className="chart-header">
                  <h4>Revenue Generated</h4>
                  <DownloadChartHam
                    chartElement={multiBarChartRef}
                    chartData={[
                      userData?.cardPurchaseRevenue,
                      userData?.proPurchaseRevenue,
                    ]}
                    csvHeaders={["Category", "Card Purchase", "Pro Users"]}
                  />
                </div>
                <div ref={multiBarChartRef}>
                  <MultiBarChart userData={userData} />
                </div>
              </div>
            </Row>
          </Col>
          <Col md={4}>
            <Row>
              <Col md={12}>
                <div className="orders-card">
                  <h3>Orders</h3>
                  <div className="title">{userData?.orderCount}</div>
                  <div className="sub-title">Total website orders</div>
                  <div className="title">{userData?.bulkOrderCount}</div>
                  <div className="sub-title">Bulk Purchase</div>
                  <div className="title">{userData?.singleOrderCount}</div>
                  <div className="sub-title">Single Admin Purchase</div>
                </div>
              </Col>
              <Col md={12}>
                <div className="revenue-card">
                  <h3>Revenue</h3>
                  <div className="revenue-sub-card">
                    <h4>Total Revenue</h4>
                    <div className="">
                      <FontAwesomeIcon icon={faCreditCard} />
                      <div className="revenue-title">
                        {userData?.totalRevenue?.total_amount}
                      </div>
                      <div className="revenue-sub-cat">Card Users</div>
                    </div>
                    <hr />
                  </div>
                  <div className="revenue-sub-card">
                    <h4>Revenue by pro users</h4>
                    <FontAwesomeIcon icon={faCreditCard} />
                    <div className="revenue-title">
                      {parseFloat(
                        userData?.totalRevenuePro?.total_amount
                      ).toFixed(2)}
                    </div>

                    <div className="revenue-sub-cat">Card Users</div>
                  </div>
                  <hr />
                  <div className="revenue-sub-card">
                    <h4>Revenue by cards</h4>
                    <div className="">
                      <FontAwesomeIcon icon={faCreditCard} />
                      <div className="revenue-title">
                        {userData?.Card || null}
                      </div>
                      <div className="revenue-sub-cat">Card Users</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import sampleFile from "../../../src/assets/images/test-file.csv";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(1),
  },

  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const UploadDialog = ({ reload }) => {
  const [open, setOpen] = React.useState(false);
  const [fileData, setFileData] = React.useState([]);
  const { id } = useParams(); 

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/plain": [".csv"],
      "application/vnd.ms-excel": [".csv"],
    },
    maxFiles: 1,
    onDrop: (files) => setFileData(files),
  });

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");
    const formData = new FormData();
    formData.append("file", fileData[0]);
     formData.append("orderId",id)
    if (fileData.length <= 0) {
      toast("Please add the data");
    } else {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/bulk-activation`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      response.status === 200 && toast("Success! Activation successful");
      setOpen(false);
      reload();
      console.log(formData);
    }
  };



// Append id with it (formData)
// const handleSubmit = async () => {

//   if (fileData.length <= 0) {
//     toast("Please add the data");
//     return;
//   }

//   // Create a new FormData instance
//   const formData = new FormData();

//   // Read and parse the CSV file using PapaParse
//   Papa.parse(fileData[0], {
//     complete: (results) => {
//       let rows = results.data;

//       // Ensure there are rows to process
//       if (rows.length > 2) {
//         // Remove the header row (first row)
//         const header = rows.shift();
        
//         // Remove the last row
//         rows.pop();

//         // Create a new header row with 'id' and the original headers
//         const newHeader = ['orderId', ...header];
        
//         // Add the new header to FormData
//         formData.append('header', newHeader.join(','));

//         // Add CSV data to FormData (with id appended to each row)
//         rows.forEach((row, index) => {
//           // Ensure the row has content before appending
//           if (row.length > 0) {
//             // Append id to the start of each row
//             const rowWithId = [id, ...row];
//             // Add each row to FormData with a unique key
//             formData.append(`row_${index}`, rowWithId.join(','));
//           }
//         });

//         // Log FormData content for debugging
//         for (let [key, value] of formData.entries()) {
//           console.log(`${key}: ${value}`);
//         }
//       } else {
//         console.log("Not enough data to process after removing header and last row.");
//       }
//     },
//     header: false, // Set to false since we're not using headers
//   });
// };

// Append with id with it and remove 0the and last data(formData)
  // const handleSubmit = async () => {
  
  //   if (fileData.length <= 0) {
  //     toast("Please add the data");
  //     return;
  //   }
  
  //   const formData = new FormData();
  
  //   Papa.parse(fileData[0], {
  //     complete: (results) => {
  //       let rows = results.data;
  
  //       if (rows.length > 2) {
  //         rows.shift();
  //         rows.pop();
  
  //         rows.forEach((row, index) => {
  //           if (row.length > 0) {
  //             const rowWithId = [id, ...row];
  //             formData.append(`row_${index}`, rowWithId.join(','));
  //           }
  //         });
  
  //         for (let [key, value] of formData.entries()) {
  //           console.log(`${key}: ${value}`);
  //         }
  //       } else {
  //         console.log("Not enough data to process after removing header and last row.");
  //       }
  //     },
  //     header: false, 
  //   });
  // };
  
  

  // Append id with it (JSON)
  // const handleSubmit = async () => {
  //   if (fileData.length <= 0) {
  //     toast("Please add the data");
  //     return;
  //   }
  
  //   // Read the CSV file using PapaParse
  //   Papa.parse(fileData[0], {
  //     complete: (results) => {
  //       const rows = results.data;
  
  //       // Append id to each row
  //       const modifiedRows = rows.map(row => [id, ...row]);
  
  //       // Display modified rows in console
  //       console.log("Modified CSV Data:", modifiedRows);
  //     },
  //     header: false, // Set to true if your CSV file includes headers
  //   });
  // };

  // Append with id with it and remove 0the and last data (JSON)
  // const handleSubmit = async () => {
  //   if (fileData.length <= 0) {
  //     toast("Please add the data");
  //     return;
  //   }
  
  //   // Read the CSV file using PapaParse
  //   Papa.parse(fileData[0], {
  //     complete: (results) => {
  //       let rows = results.data;
  
  //       // Ensure there are rows to process
  //       if (rows.length > 1) {
  //         // Remove the header row (first row)
  //         rows.shift();
  
  //         // Remove the last row
  //         rows.pop();
  
  //         // Append id to each remaining row
  //         const modifiedRows = rows.map(row => [id, ...row]);
  
  //         // Display modified rows in console
  //         console.log("Modified CSV Data:", modifiedRows);
  //       } else {
  //         console.log("No data to process after removing header and last row.");
  //       }
  //     },
  //     header: false, // Set to false since we're not using headers
  //   });
  // };
  
  
  return (
    <div>
      <StyledButton text={"Bulk Profile Creation"} onClick={handleClickOpen} />
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>Bulk Profile creation</DialogTitle>
        <DialogContent>
          <section className="container">
          <a href={sampleFile} download="samplebulkactivation.csv" style={{ fontSize: "14px" }}>
              Download sample import file
            </a>
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                border: "2px dashed #aaa",
                padding: "0 25%",
                height: "100px",
                margin: "10px 0",
                borderRadius: "10px",
                backgroundColor: "#eee",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input {...getInputProps()} />
              <p style={{ fontWeight: 500 }}>
                Select your files or drop it here
              </p>
            </div>
            <aside>
              <div style={{ fontWeight: 500 }}>Files</div>
              {fileData?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <span key={fileData[0].path}>
                      <FolderIcon style={{ color: "rgb(100,116,139)" }} />{" "}
                      {fileData[0].path}
                    </span>
                  </div>
                  <StyledButton
                    text={"Delete"}
                    color="#c9302c"
                    hovercolor="#d9534f"
                    onClick={() => setFileData([])}
                  />
                </div>
              )}
            </aside>
          </section>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Cancel"}
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton text={"Upload"} onClick={handleSubmit} />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default UploadDialog;

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import FolderIcon from "@mui/icons-material/Folder";
import sampleFile from "../../../src/assets/images/profilecreate.csv";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LinearProgress from "@mui/material/LinearProgress";
import Papa from 'papaparse';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    borderRadius: "15px",
    padding: theme.spacing(1),
  },
  "& .MuiTypography-root": {
    fontWeight: 700,
  },
}));

const ProfileUploadDialog = ({ reload }) => {
  const [open, setOpen] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "text/plain": [".csv"],
      "application/vnd.ms-excel": [".csv"],
    },
    maxFiles: 1,
    onDrop: (files) => setFileData(files),
  });

  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    let totalRows = 0;
    let errorOccurred = false;
    const authToken = localStorage.getItem("authToken");
        
    if (!authToken) {
      navigate("/sign-in");
      return;
    }
  
    if (fileData.length <= 0) {
      toast.error("Please add the data", {
        autoClose: 2000
      });
      return;
    }
  
    setUploading(true);
    const file = fileData[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      const csvData = event.target.result;
  
      try {
        const parsedData = Papa.parse(csvData, {
          header: true,
        });
  
        totalRows = parsedData.data.length;
        let uploadedRows = 0;
  
        for (let i = 0; i < totalRows; i++) {
          const rowData = parsedData.data[i];
          const formData = new FormData();
          formData.append("csv", new Blob([Papa.unparse([rowData])], { type: 'text/csv' }));
  
          try {
            await axios.post(
              `${process.env.REACT_APP_BASE_URL}/v1/api/admin/activateUsersFromCSV`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${authToken}`,
                  'Content-Type': 'multipart/form-data'
                },
              }
            );
  
            const progress = ((i + 1) / totalRows) * 100;
            setUploadProgress(progress);
            uploadedRows++;
          } catch (error) {
            console.error('Error during upload:', error);
            errorOccurred = true;
            if (error.response && error.response.data && error.response.data.errors) {
              const errorDetails = error.response.data.errors[0];
              const errorMessage = `Error uploading row ${i + 1}: ${errorDetails.errors.map(err => err.message).join(", ")}`;
              toast.error(errorMessage, {
                autoClose: 5000
              });
            } else {
              toast.error(`Error uploading row ${i + 1}: ${error.message}`, {
                autoClose: 5000
              });
            }
          }
        }
  
        if (!errorOccurred && uploadedRows === totalRows) {
          toast.success("Success! All Data uploaded successfully", {
            autoClose: 2000
          });
          setOpen(false);
          reload();
        }
      } catch (error) {
        console.error('Error during file reading or parsing:', error);
        toast.error("An error occurred during upload. Please try again.", {
          autoClose: 2000
        });
      } finally {
        setUploading(false);
        setUploadProgress(0);
      }
    };
  
    reader.readAsText(file);
  };
  
  
  
  
  
  
  
  

  return (
    <div>
      <StyledButton text={"Upload"} onClick={handleClickOpen} />
      <BootstrapDialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle>BUlk Exports</DialogTitle>
        <DialogContent>
          <section className="container">
            <a href={sampleFile} download="sampleprofilecreation.csv" style={{ fontSize: "14px" }}>
              Download sample import file
            </a>
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                border: "2px dashed #aaa",
                padding: "0 25%",
                height: "100px",
                margin: "10px 0",
                borderRadius: "10px",
                backgroundColor: "#eee",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input {...getInputProps()} />
              <p style={{ fontWeight: 500 }}>
                Select your files or drop it here
              </p>
            </div>
            <aside>
              <div style={{ fontWeight: 500 }}>Files</div>
              {fileData?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <span key={fileData[0].path}>
                      <FolderIcon style={{ color: "rgb(100,116,139)" }} />{" "}
                      {fileData[0].path}
                    </span>
                  </div>
                  <StyledButton
                    text={"Delete"}
                    color="#c9302c"
                    hovercolor="#d9534f"
                    onClick={() => setFileData([])}
                  />
                </div>
              )}
            </aside>
          </section>
        </DialogContent>
        {uploading && (
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', flexDirection: 'column' }}>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              style={{ width: '100%', backgroundColor: '#d8ffd8', marginBottom: '10px' }}
              sx={{
                '& .MuiLinearProgress-barColorPrimary': {
                  backgroundColor: '#4caf50',
                },
              }}
            />
            <div>{uploadProgress.toFixed(2)}% completed</div>
          </div>
        )}
        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Cancel"}
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton
            text={uploading ? "Uploading..." : "Upload"}
            onClick={handleSubmit}
            disabled={uploading}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProfileUploadDialog;
import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import axios from "axios";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import TextEditor from "../../Components/Blogs/TextEditor";
import StlButton from "../../Components/StyledButton";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography'; 

const StyledButton = styled(Button)(({ theme, variant }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  color: "rgba(0, 0, 0, 0.6)",
  fontSize: "16px",
  padding: "8px 12px",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  width: "100%",
  "&:hover": {
    backgroundColor: "#fff",
    border: "1px solid rgba(0, 0, 0, 0.8)",
  },
  justifyContent: "start",
  textTransform: "none",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  // border: "2px solid red",
}));
const AWS_S3_BUCKET_URL=process.env.REACT_APP_AWS_S3_BUCKET_URL;

const TextField = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    borderColor: "#E0E3E7",
    fontSize: 16,
    padding: "10px 12px",
  },
}));

const UpdateBlog = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [slug, setSlug] = useState("");
  const [keywords, setKeywords] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDesc, setMetaDesc] = useState("");
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [currentBlogImage, setCurrentBlogImage] = useState("");
  const [showTitleError, setShowTitleError] = useState(false);
  const [showCatError, setShowCatError] = useState(false);
  const [showSlugError, setShowSlugError] = useState(false);
  const [showImageError, setShowImageError] = useState(false);
  const [showDescError, setShowDescError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = localStorage.getItem("authToken");
        if (!authToken) navigate("/sign-in");

        const catResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-all-category`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setCategoryList(
          catResponse?.data?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        );

        const subCatResponse = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-all-subcategory`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setSubCategoryList(
          subCatResponse?.data?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        );

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/get-single-blogs/${id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        setTitle(response?.data?.title);
        setCategory(response?.data?.category);
        setCurrentBlogImage(response?.data?.image);
        setSubcategory(response?.data?.subcategory);
        setSlug(response?.data?.slug);
        setKeywords(response?.data?.keywords);
        setMetaTitle(response?.data?.metaTitle);
        setMetaDesc(response?.data?.metaDesc);
        setImage(response?.data?.image);
        setDescription(response?.data?.description);
      } catch (error) {
        if (error?.response?.data?.error === "failed to authenticate token")
          navigate("/sign-in");
      }
    };

    fetchData();
  }, [navigate, id]);

  const handleSubmit = async () => {
    const data = {
      title,
      category,
      subcategory,
      slug,
      keywords,
      metaTitle,
      metaDesc,
      image,
      description,
    };

    let formData = new FormData();
    for (const key in data) {
      if (data[key] !== "") formData.append(key, data[key]);
    }

    const authToken = localStorage.getItem("authToken");
    if (!authToken) navigate("/sign-in");

    if (title === "") {
      setShowTitleError(true);
    }
    if (category === "") {
      setShowCatError(true);
    }
    if (slug === "") {
      setShowSlugError(true);
    }
    if (image === "") {
      setShowImageError(true);
    }
    if (description === "") {
      setShowDescError(true);
    } else if (
      title !== "" ||
      category !== "" ||
      slug !== "" ||
      image !== "" ||
      description !== ""
    ) {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/v1/api/admin/update-blogs/${id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      response.status === 200 && toast("Success! The blog has been updated");
      navigate("/blogs");
    }
  };

  const handleDescriptionChange = (html) => {
    setDescription(html);
  };

  return (
    <div className="blog-container">
      <div className="blog-header">
        <p>
        <Link
    to="/dashboard"
    style={{
      textDecoration: 'none',
      color: '#454545',
      borderBottom: '1px solid transparent', // Initial border
      transition: 'border-bottom 0.3s ease', // Add smooth transition
    }}
    onMouseOver={(e) => (e.target.style.borderBottom = '1px solid #454545')} // Show underline on hover
    onMouseOut={(e) => (e.target.style.borderBottom = '1px solid transparent')} // Hide underline on mouseout
  >
    Home
  </Link>{" "}/ <Link
    to="/blogs"
    style={{
      textDecoration: 'none',
      color: '#454545',
      borderBottom: '1px solid transparent', // Initial border
      transition: 'border-bottom 0.3s ease', // Add smooth transition
    }}
    onMouseOver={(e) => (e.target.style.borderBottom = '1px solid #454545')} // Show underline on hover
    onMouseOut={(e) => (e.target.style.borderBottom = '1px solid transparent')} // Hide underline on mouseout
  >
    Blogs
  </Link>{" "} / <strong>Edit Blog</strong>
        </p>
        <h4 style={{fontSize:'1.5rem'}} >Edit Blog</h4>
      </div>
      <Box sx={{ flexGrow: 1, marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Item>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  htmlFor="title"
                  sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
                >
                  Title
                </InputLabel>
                <TextField
                  autoFocus
                  value={title}
                  required
                  error={showTitleError ? true : false}
                  margin="dense"
                  id="title"
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  helperText={showTitleError && "Title is required"}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              <FormControl
                variant="standard"
                fullWidth
                error={showCatError ? true : false}
              >
                <InputLabel
                  shrink
                  htmlFor="select-category"
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    color: "#000",
                  }}
                >
                  Select Category
                </InputLabel>
                <Select
                  labelId="select-category"
                  id="Add Category"
                  value={category}
                  size="small"
                  onChange={(e) => setCategory(parseInt(e.target.value))}
                  input={<TextField />}
                >
                  {categoryList?.map((category, index) => (
                    <MenuItem key={index} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
                {showCatError && (
                  <FormHelperText>Category is required</FormHelperText>
                )}
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  htmlFor="select-sub-category"
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                    color: "#000",
                  }}
                >
                  Select Sub Category
                </InputLabel>
                <Select
                  labelId="select-category"
                  id="Add Category"
                  value={subcategory}
                  label="Add Category"
                  size="small"
                  onChange={(e) => setSubcategory(parseInt(e.target.value))}
                  input={<TextField />}
                >
                  {subCategoryList?.map(
                    (subCategory, index) =>
                      subCategory.categoryId === category && (
                        <MenuItem key={index} value={subCategory.id}>
                          {subCategory.name}
                        </MenuItem>
                      )
                  )}
                </Select>
                {/* {showCatError && (
                    <FormHelperText>Category is required</FormHelperText>
                  )} */}
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  htmlFor="name"
                  sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
                >
                  Slug
                </InputLabel>
                <TextField
                  autoFocus
                  value={slug}
                  required
                  error={showSlugError ? true : false}
                  margin="dense"
                  id="slug"
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  helperText={showSlugError && "Category Name is required"}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  htmlFor="keywords"
                  sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
                >
                  Keywords
                </InputLabel>
                <TextField
                  autoFocus
                  value={keywords}
                  required
                  margin="dense"
                  id="keywords"
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={(e) => setKeywords(e.target.value)}
                />
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  htmlFor="meta-title"
                  sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
                >
                  Meta Title
                </InputLabel>
                <TextField
                  autoFocus
                  value={metaTitle}
                  required
                  margin="dense"
                  id="meta-title"
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={(e) => setMetaTitle(e.target.value)}
                />
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  htmlFor="meta-desc"
                  sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
                >
                  Meta Description
                </InputLabel>
                <TextField
                  autoFocus
                  value={metaDesc}
                  required
                  margin="dense"
                  id="meta-desc"
                  type="text"
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={(e) => setMetaDesc(e.target.value)}
                />
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item>
              <FormControl variant="standard" fullWidth>
                <InputLabel
                  shrink
                  htmlFor="meta-description"
                  sx={{ fontWeight: 700, fontSize: "20px", color: "#000" }}
                >
                  Featured Image
                </InputLabel>
                
                <StyledButton
                  variant="outlined"
                  component="label"
                  // style={showImageError && { border: "1px solid red" }}
                >
                  {image?.name || "No file chosen"}
                  <input
                    type="file"
                    hidden
                    onChange={(e) => setImage(e.target.files[0])}
                  />
              
                {/* <InputAdornment position="start" >
                  <AttachFileIcon />
                </InputAdornment> */}
                 <IconButton
              color="primary"
              aria-label="attach-file"
              component="span"
              style={{ marginLeft: 'auto' }}
            >
              <AttachFileIcon />
            </IconButton>

                </StyledButton> 
                <FormHelperText>
                  Current image: {currentBlogImage}
                  <a
                    href={`https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${currentBlogImage}`}
                  >
                    &nbsp; download
                  </a>
                </FormHelperText>
                <div
                  style={{
                    color: "red",
                    textAlign: "left",
                    paddingLeft: "8px",
                  }}
                >
                  {showImageError && "Image is required"}
                </div>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Item>
              <div>
              <Typography variant="subtitle1" color="textSecondary" style={{ textAlign: 'left', fontWeight: 700, fontSize: "15px", color: "#000"}}>
        Content 
      </Typography>
                <TextEditor
                  onChange={handleDescriptionChange}
                  value={description}
                />
              </div>
              <div
                style={{
                  color: "red",
                  textAlign: "left",
                  paddingLeft: "8px",
                }}
              >
                {showDescError && "Description is required"}
              </div>
            </Item>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Item>
              <StlButton text={"submit"} onClick={handleSubmit} />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default UpdateBlog;

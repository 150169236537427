import React, { useState } from "react";
import axios from "axios";
import "./Login.css";
import {  useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const emailError = useState("");
  // const passwordError = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailError, setEmailError] = useState(""); // Initialize emailError state
  const [passwordError, setPasswordError] = useState(""); // Initialize passwordError state

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const handlePasswordVisibilityToggle = () => {
    setPasswordVisible(!passwordVisible); // Toggle the visibility state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true;
    // Validate the email field
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else {
      // Clear the error message if the email is provided
      setEmailError("");
    }
    // Validate the password field
    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else {
      // Clear the error message if the password is provided
      setPasswordError("");
    }
    // If any validation fails, do not proceed with the API request
    if (!isValid) {
      return;
    }

    // Make the API request
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/auth/admin-signin`,
        {
          email,
          password,
        }
      );
      // Assuming the API returns a success status code (e.g., 200)
      if (response.status === 200) {
        // Redirect to the Home page
        navigate("/dashboard");
        const authToken = response.data;
        // Store the token in localStorage
        localStorage.setItem("authToken", authToken);
        localStorage.setItem("authUser", email);
        // Display a success toast message
        toast.success("Successfully signed in.");
      } else {
        // Handle other response statuses or errors from the API
        console.log("API request failed with status: " + response.status);
        if (response.status === 401) {
          // Unauthorized: Credentials do not match
          // Check the response data for specific error messages
          if (response.data && response.data.error === "EmailNotRegistered") {
            toast.error("Email is not registered.");
          } else if (
            response.data &&
            response.data.error === "InvalidPassword"
          ) {
            toast.error("Email and password do not match.");
          } else {
            setError("Email or Password incorrect"); // Set the error message
          }
        } else {
          // Handle other API errors here
          toast.error("An error occurred while signing in.");
        }
      }
    } catch (error) {
      // Handle API request errors, e.g., network issues
      console.error("API request error", error);
      // setError("An error occurred while signing in."); // Set the error message
      setError("Wrong Email or Password"); // Set the error message
    }
  };
  const verticalLineStyle = {
    borderLeft: "2px solid lightgray",
    height: "80%",
    position: "absolute",
    left: "-10px",
  };
  return (
    <div className="wrapper">
      <ToastContainer />
      <nav className="navbar navbar-expand-lg fixed">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              className="header-logo img-fluid"
              src={require("../../assets/images/c.png")}
              alt="Logo"
              style={{ maxWidth: "170px" }}
            />
          </a>
          <button
            className={`navbar-toggler ${isMenuOpen ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/faq"
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    fontWeight: "normal", // Add this line
                  }}
                >
                  {!isMenuOpen && <span style={verticalLineStyle}></span>}
                  Need any help?
                </a>
              </li>
              {/* Add more menu items here */}
            </ul>
          </div>
        </div>
      </nav>

      <div className="container border">
        <section className="login">
          <div className="row align-items-center">
            <div
              className="col-lg-6  d-lg-block  login-left d-none d-sm-block"
              style={{ marginTop: "110px" }}
            >
              <img
                src={require("../../assets/images/login-group.png")}
                className="img-fluid d-none d-lg-block d-block mx-auto login-image"
                alt="Login"
                // style={{ height: "500px" }}
                style={{ maxWidth: "600px", width: "500px" }}
              />
            </div>

            <div className="col-lg-6 login-right">
              <div className="login-account">
                <div>
                  <img
                    src={require("../../assets/images/logo-icon.png")}
                    className="footer-logo d-block mx-auto mt-5"
                    alt="Logo Icon"
                    style={{
                      maxHeight: "100%",
                      width: "50px",
                      marginBottom: "30px",
                    }}
                  />
                  <div className="text-center">
                    <h2 style={{ color: "#04459D", fontWeight: "bolder",fontSize:'2rem' }}>
                      Welcome
                    </h2>
                    <p className="d-flex justify-content-center">
                      Don't have any account?
                      <p
                        className="d-flex signup"
                        style={{ marginLeft: "10px" }}
                      >
                        <a className=" fw-bold" href="/sign-up" style={{textDecoration:'none'}}>
                          Sign up
                        </a>
                      </p>
                    </p>
                  </div>
                  <div className="text-center"></div>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="row justify-content-center">
                    {/* <div className="col align-self-center"> */}

                    <div className="row justify-content-center">
                      <div className="col-8 form-group">
                        {error && (
                          <p
                            style={{
                              // color: "red",
                              fontSize: "14px",
                              marginBottom: "4px",
                              // textAlign: "center",
                            }}
                          >
                            <div class="alert alert-danger" role="alert">
                              {error}
                            </div>
                          </p>
                        )}

                        <label for="exampleInputEmail1">Email address</label>
                        <input
                          id="email"
                          type="text"
                          className="form-control"
                          placeholder="Email Address"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          style={{ fontSize: "14px", height: "45px" }}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "4px",
                            }}
                          >
                            {emailError}
                          </p>
                        )}
                      </div>
                      {/* </div> */}
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-8 form-group">
                        <label htmlFor="password">Password</label>
                        <div className="input-group">
                          <input
                            id="password"
                            type={passwordVisible ? "text" : "password"} // Conditionally set input type
                            className="form-control in2"
                            placeholder="Password"
                            aria-label="Password"
                            aria-describedby="basic-addon2"
                            style={{ fontSize: "14px", height: "45px" }}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={handlePasswordVisibilityToggle}
                              style={{ height: "45px" }}
                            >
                              {passwordVisible ? (
                                <i className="far fa-eye-slash"></i> // Eye icon with a slash when visible
                              ) : (
                                <i className="far fa-eye"></i> // Eye icon when hidden
                              )}
                            </button>
                          </div>
                        </div>
                        {passwordError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "4px",
                            }}
                          >
                            {passwordError}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      <div className="col-8 d-flex justify-content-between">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="rememberMe"
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rememberMe"
                          >
                            Remember me
                          </label>
                        </div>
                        <p className="d-flex">
                          <a
                            className="gray"
                            href="/forgot-password"
                            style={{ color: "gray", textDecoration: "none" }}
                          >
                            Forgot Password?
                            {/* <span>/ Reset</span> */}
                          </a>
                        </p>
                      </div>
                    </div>

                    <div
                      className="row justify-content-center"
                      style={{ marginBottom: "60px", marginTop: "10px" }}
                    >
                      <div className="col-8">
                        <button
                          id="login-button"
                          className="col-12 btn btn-primary "
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="footer" style={{ marginTop: "30px" }}>
        <div className="container">
          <div className="">
            {/* Logo */}
            <img
              src={require("../../assets/images/c.png")}
              alt="Connct Logo"
              style={{ maxWidth: "200px" }}
            />
            {/* Tagline */}
            <div style={{ margin: "10px 0", fontSize: "20px" }}>
              dont just say hello, just 👋 Connct
            </div>
            {/* Horizontal Line */}
            <hr style={{ backgroundColor: "lightgray", height: "2px" }} />
            {/* Copyright Notice */}
            <div style={{ margin: "10px 0" }}>
              Copyright © 2022. All rights reserved Connct
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React from "react";
import "./Loader.css";

export default function Loader({ isLoading }) {
  return (
    isLoading && (
      <div className="spinner-container">
        <div className="loading-spinner"></div>
      </div>
    )
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import StyledButton from "../../Components/StyledButton";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { utils, writeFile } from "xlsx";
import ProductDetails from "../../Components/Orders/ProductDetails";
import UpdateAmountDialog from "../../Components/Orders/UpdateAmountDialog";
import { Link } from "react-router-dom";
import Loader from "../../Components/Loader";
import JSZip from "jszip";

const Item = styled(Paper)(({ theme }) => ({
  padding: "0px 10px",
  textAlign: "left",
  color: "#000",
  marginBottom: "35px",
}));

const Title = styled(Box)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  marginBottom: "5px",
}));

const Content = styled(Box)(({ theme }) => ({
  fontSize: "12px",
  marginBottom: "15px",
}));

const OrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [details, setDetails] = useState({});
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const handleReload = () => setReload(!reload);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const authToken = localStorage.getItem("authToken");
        if (!authToken) navigate("/sign-in");
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/admin/single-order/${id}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        response.status === 200 && setIsLoading(false);
        setDetails(response?.data?.order);

        // Assuming the 'amount' field is present in the API response
        setAmount(response?.data?.order?.amount || 0);
      } catch (error) {
        if (error?.response?.data?.error === "failed to authenticate token")
          navigate("/sign-in");
      }
    }

    fetchData();
  }, [id, navigate, reload]);

  const getDate = (date) => {
    const inputDate = new Date(date);

    // Define an array of month names for formatting
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Define an array of day names for formatting
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Format the date
    const formattedDate = `${dayNames[inputDate.getUTCDay()]}, ${
      monthNames[inputDate.getUTCMonth()]
    } ${inputDate.getUTCDate()}, ${inputDate.getUTCFullYear()}, ${inputDate.toLocaleTimeString(
      "en-US",
      { hour: "numeric", minute: "2-digit" }
    )}`;

    return formattedDate;
  };

  const handleExport = () => {
    const data = [];

    for (let i = 0; i < details?.UserSubscriptions.length; i++) {
      const productData = details?.UserSubscriptions[i];
      data.push({
        "Customer ID": details?.User?.customerId, // Include the customer ID here
        "Customer Name": productData?.userData?.fullName,
        Email: productData?.userData?.email,
        "Order ID": details?.orderId,
        Contact: productData?.userData?.phonenumber,
        POC: productData?.userData?.poc,
        Product: productData?.cardStyle,
        Price: productData?.Card?.price,
        "Activation Key": productData?.activationKey,
      });
    }

    const ws = utils.json_to_sheet(data);

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");

    writeFile(wb, `${details?.fullName}_${details?.orderId}.xlsx`);
  };

  const downloadQRCode = async () => {
    const zip = new JSZip();

    try {
      const responses = await Promise.all(
        details?.UserSubscriptions?.map(async (userSubs) => {
          const response = await fetch(userSubs?.qrCode);
          return response.blob();
        })
      );

      for (let i = 0; i < responses.length; i++) {
        const userName =
          details?.UserSubscriptions[i]?.userData?.name ||
          details?.UserSubscriptions[i]?.userData?.fullName;
        const fileName = userName
          // ? `${userName}_${details?.UserSubscriptions[i]?.id}.png`
          // : `user_${details?.UserSubscriptions[i]?.id}.png`; // Default filename
          ? `${userName}_${details.User.customerId}.png`
          : `user_${details.User.customerId}.png`; // Default filename

        zip.file(fileName, responses[i]);
        console.log(details.User.customerId)
      }

      const zipped = await zip.generateAsync({
        type: "blob",
        streamFiles: true,
      });
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(zipped);
      a.download = `${details?.fullName}_${details?.orderId}_QR.zip`;
      a.click();
    } catch (error) {
      console.error("Error downloading QR codes:", error);
      // Handle error gracefully, e.g., display an error message to the user
    }
  };

  // const getTotalAmount = () => {
  //   let total = 0;
  //   details?.UserSubscriptions?.forEach((userSubs) => {
  //     total += userSubs?.Card?.price;
  //   });
  //   return total;
  // };

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          margin: "20px 30px",
        }}
      >
        <Box
          sx={{ borderBottom: "1px solid #66656536", paddingBottom: "20px" }}
        >
          <p>
            <Link
              to="/dashboard"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Home
            </Link>{" "}
            /{" "}
            <Link
              to="/orders"
              style={{
                textDecoration: "none",
                color: "#454545",
                borderBottom: "1px solid transparent", // Initial border
                transition: "border-bottom 0.3s ease", // Add smooth transition
              }}
              onMouseOver={(e) =>
                (e.target.style.borderBottom = "1px solid #454545")
              } // Show underline on hover
              onMouseOut={(e) =>
                (e.target.style.borderBottom = "1px solid transparent")
              } // Hide underline on mouseout
            >
              Orders
            </Link>{" "}
            / <strong>View Orders</strong>
          </p>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap={"wrap"}
          >
            <h4>Order Details</h4>
            <Stack direction="row" spacing={1}>
              <StyledButton text={"Export Data"} onClick={handleExport} />
              <StyledButton
                text={"Download QR Codes"}
                onClick={downloadQRCode}
              />
              <StyledButton text={"Back"} onClick={() => navigate(-1)} />
            </Stack>
          </Stack>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            marginTop: 2,
            borderBottom: "1px solid #66656536",
          }}
        >
          <Box>
            <Grid container>
              <Grid item xs={12} sm={2}>
                <Item>
                  <Title>Order ID</Title>
                  <Content>{details?.orderId}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Item>
                  <Title>Order Date</Title>
                  <Content>{getDate(details?.createdAt)}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Item>
                  <Title>Customer Name</Title>
                  <Content>{details?.fullName}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Item>
                  <Title>Customer ID</Title>
                  <Content>{details?.User?.customerId}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Item>
                  <Title>Company</Title>
                  <Content>{details?.company}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Item>
                  <Title>Email</Title>
                  <Content>{details?.email}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Item>
                  <Title>Phone Number</Title>
                  <Content>{details?.phonenumber}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Item>
                  <Title>Total Amount</Title>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Content>
                      {details.currency === "INR" ? "₹" : "$"}
                      {amount}
                    </Content>
                    <UpdateAmountDialog
                      reload={handleReload}
                      prevAmount={details?.amount}
                      orderId={details?.id}
                    />
                  </div>
                </Item>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Item>
                  <Title>Address</Title>
                  <Content>{details?.address}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Item>
                  <Title>Quantity</Title>
                  <Content>{details?.quantity}</Content>
                </Item>
              </Grid>
              <Grid item xs={12} sm={2}>
              {details?.OrderCustomizations?.length > 0 && (
                <Item>
                  <Title>Customization Available</Title>
                </Item>
              )}

              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Item>
                  <Title>Design Assistance</Title>
                  <Content>
                    {details?.UserSubscriptions &&
                    details.UserSubscriptions[0]?.customizationAssistance ===
                      true
                      ? "Yes"
                      : "No"}
                  </Content>
                </Item>
              </Grid> */}
            </Grid>
          </Box>
        </Box>
        <Box>
          <Box sx={{ fontWeight: 700, paddingTop: "15px" }}></Box>
          <ProductDetails
            details={details}
            customerId={details?.User?.customerId}
            orderId={details?.orderId}
            handleReload={handleReload}
          />
        </Box>
      </Box>
    </>
  );
};

export default OrderDetails;

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import StyledButton from "../StyledButton";
import Slide from '@mui/material/Slide';
import { styled } from "@mui/material/styles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: "20px", 
      padding: theme.spacing(2),
    },
  }));
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const DeleteMultipleDialog = ({ selectedOrders, handleDeleteMultipleFn,types }) => {
  const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);

  const handleOpenDialog = () => {
    setOpenDeleteMultiple(true);
  };

  const handleCloseDialog = () => {
    setOpenDeleteMultiple(false);
  };

  const handleDeleteMultiple = () => {
    if (handleDeleteMultipleFn) {
      handleDeleteMultipleFn(selectedOrders);
    }
    setOpenDeleteMultiple(false);
  };

  return (
    <>
      <StyledButton
        text={selectedOrders.length > 1 ? 'Delete Selected' : 'Delete'}
        color="#c9302c"
        hovercolor="#d9534f"
        onClick={handleOpenDialog}
      />
      <StyledDialog
        open={openDeleteMultiple}
        onClose={handleCloseDialog}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="error">
          {`Delete selected ${types}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selectedOrders.length > 1 ? "these" : "this"} <b>{selectedOrders.length}</b> {types}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton
            variant="outlined"
            text={"Cancel"}
            onClick={handleCloseDialog}
            color="primary"
          />
          <StyledButton
            text={"Delete"}
            color="#c9302c"
            hovercolor="#d9534f"
            onClick={handleDeleteMultiple}
            autoFocus
          />
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default DeleteMultipleDialog;
